import { env } from '@/libs/env'

import service, { useJwtPublic as servicePublic } from './service'

export default {
  searchFlight(payload = {}) {
    return service.post('/flights/search', payload)
  },

  searchClassBooking(payload = {}) {
    return service.post('/class-booking/search', payload)
  },

  // calculatePriceClassBooking(payload = {}) {
  //   return service.post('/class-booking/price', payload)
  // },

  fetchAirports(payload = {}) {
    return service.get('/metadata/airports-iata', payload)
  },

  fetchAirportsByCodes(params = {}) {
    if (env.isProduction) {
      // FIXME : remove public, prod tạm thời chuyển sang public tránh rate limit
      return servicePublic.get('/public/airports-iata/codes', params)
    }
    return service.get('/metadata/airports-iata/codes', params)
  },

  createBooking(payload = {}) {
    return service.post('/flights/book', payload)
  },

  draftBooking(payload = {}) {
    return service.post('/flights/draft', payload)
  },

  createGroupBooking(payload = {}) {
    return service.post('/flights/book-group', payload)
  },

  fetchAirportGroup(payload = {}) {
    return service.get('/metadata/airports/group', payload)
  },

  fetchCheapestFare(payload = {}) {
    return service.get('/flights/cheapest-fare', payload)
  },

  fetchListAncillary(payload = {}) {
    return service.post('/flights/list-ancillary', payload)
  },

  fetchSeatMap(payload = {}) {
    return service.post('/flights/seat-map', payload)
  },

  confirmPrice(payload = {}) {
    return service.post('/flights/confirm-price', payload)
  },

  // ANCHOR: Search flight v2
  searchFlightv2(payload = {}) {
    return service.post('/v2/flights/search', payload)
  },
  getFlightSearchOperation(searchId) {
    return service.get(`/v2/flights/search/${searchId}`)
  },
  getResourceItinerary(resourceId) {
    return service.get(`/v2/flights/search/resources/${resourceId}`)
  },

  membershipCard(payload = {}, params = {}) {
    return service.post('/membership_card/', payload, params)
  },

  getFareRules(payload = {}) {
    return service.post('/flights/fare-rules', payload)
  },

  calculatePrice(payload = {}) {
    return service.post('/flights/calculate-price', payload)
  },

  getClassBookingInfoTrip(payload) {
    return service.post('/class-booking/search-id', payload)
  },

  retrieveVisa(payload) {
    return service.post('/commands/retrieve-visa', payload)
  },

  fetchFlightPreparations(params = {}) {
    return service.get('/bookings/flight-preparation/search', params)
  },

  getAgencyBalance() { // số dư hãng
    return service.get('/flights/agency-balance')
  },

  getAvailableDistributorSource() { // danh sách source đang avail
    return service.get('/flights/available-distributor')
  },

  // recalculatePriceCommand(payload) {
  //   return service.post('/commands/calculate-price-command', payload)
  // },
  recalculatePriceItinerary(payload) {
    return service.post('/commands/calculate-price-itinerary', payload)
  },
  recalculatePriceCommandAddDob(payload) {
    return service.post('/commands/add-dob', payload)
  },

}
