/* eslint-disable import/no-extraneous-dependencies */
import moment from 'moment'
import 'moment/locale/vi'
import capitalize from 'lodash/capitalize'

import { isToday } from './utils'

const getLocaleLanguage = localStorage.getItem('Locale') || process.env.VUE_APP_DEFAULT_LANGUAGE
moment.locale(getLocaleLanguage)

export const kFormatter = num => num > 999 ? `${(num / 1000).toFixed(0)}k` : num

export const currencyVNFormatter = num => {
  const number = parseFloat(num.replace(/,/g, ''))

  if (Number.isNaN(number)) return '0'

  if (number >= 1000000) {
    const result = Number((number / 1000000).toFixed(3).replace(/\.000$/, ''))
      || (number / 1000000).toFixed(3).replace(/\.000$/, '')
    return `${result}M`
  }
  if (number >= 1000) {
    return `${(number / 1000).toFixed(3).replace(/\.000$/, '')}k`
  }
  return number.toString()
}

export const sizeFormatter = num => {
  if (num > 999999) return `${(num / 1000000).toFixed(0)}MB`
  if (num > 999) return `${(num / 1000).toFixed(0)}kB`
  return `${num}B`
}

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })
  return capitalizedArray.join(' ')
}

export const avatarText = value => {
  if (!value) return ''
  const nameArray = value.split(' ')
  const result = nameArray.map(word => word.charAt(0).toUpperCase()).join('')
  return result.length > 2 ? result.slice(0, 2) : result
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' },
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const getTextDay = value => [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
][new Date(value).getDay()]

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

export const dateTime = value => !value ? '' : moment(value).format('HH:mm | DD/MM/YYYY')

export const getDate = value => !value ? '' : moment(value).format('DD/MM/YYYY')

export const getHour = value => (!value ? '' : moment(value).format('HH:mm'))

export const getMinTime = array => moment.min(array.map(item => moment(item)))

// yyyy-mm-dd to dd/mm/yyyy
export const formatDateMonth = (value, format = 'DD/MM/YYYY') => moment(value).format(format)

// dd/mm/yyyy to yyyy-mm-dd
// export const formatDateMonthReserve = value => {
//   const result = moment(value).format('YYYY-MM-DD')
//   // eslint-disable-next-line no-console
//   return result
// }

// ISO Date Time => {date, time}
export const convertISODateTime = (value, timezone = '+07:00') => {
  // Handle case YYYY-MM-DDTHH:mm:ss+[-]timezone
  if (typeof value === 'string' && value.includes('T')) {
    const timeValue = value.split('T')[1] || null
    if (timeValue.includes('+') || timeValue.includes('-')) {
      value = value.slice(0, -6)
      timezone = '+00:00'
    }
  }
  if (timezone === 'UTC') timezone = '+00:00'
  if (!value) return ''

  moment.locale('en') // Đặt ngôn ngữ

  return {
    date: moment.utc(value).utcOffset(timezone).format('DD/MM/YYYY'),
    dateFilter: moment.utc(value).utcOffset(timezone).format('YYYY-MM-DD'),
    day: moment.utc(value).utcOffset(timezone).format('DD'),
    dayOfWeek: moment.utc(value).utcOffset(timezone).format('ddd'), // Thu, Sun
    time: moment.utc(value).utcOffset(timezone).format('HH:mm'),
    dateTime: moment
      .utc(value)
      .utcOffset(timezone)
      .format('HH:mm | DD/MM/YYYY'),
    dateTimeSecond: moment
      .utc(value)
      .utcOffset(timezone)
      .format('HH:mm:ss | DD/MM/YYYY'),
    timeDate: moment
      .utc(value)
      .utcOffset(timezone)
      .format('DD/MM/YYYY | HH:mm'),
    ISOdatetime: moment
      .utc(value)
      .utcOffset(timezone)
      .format('YYYY-MM-DDTHH:mm:ssZ'), // ISO 8601
    dayMonth: moment
      .utc(value)
      .utcOffset(timezone)
      .format('DDMMM')
      .toUpperCase(), // 30JUL
    hourMin: moment.utc(value).utcOffset(timezone).format('HHmm'), // 19:45 > 1945, 20:00 > 2000, ...
    dayAndMonth: moment.utc(value).utcOffset(timezone).format('DD/MM'),
  }
}

const getFormattedDayWithMonth = (value, timezone) => {
  const dayWithMonth = moment
    .utc(value)
    .utcOffset(timezone)
    .format('dddd, DD/MM')
  return capitalize(dayWithMonth)
}

const resolveViDayOfWeek = value => {
  switch (value) {
    case '0':
      return 'CN'
    case '1':
      return 'T2'
    case '2':
      return 'T3'
    case '3':
      return 'T4'
    case '4':
      return 'T5'
    case '5':
      return 'T6'
    case '6':
      return 'T7'
    default:
      return ''
  }
}

export const convertISODateTimeLangVN = (value, timezone = '+07:00') => {
  if (timezone === 'UTC') timezone = '+00:00'
  if (!value) return ''

  moment.locale('vi') // Đặt ngôn ngữ
  const formattedDay = moment.utc(value).utcOffset(timezone).format('d, DD/MM')
  const shortDay = `${resolveViDayOfWeek(
    formattedDay.charAt(0).toUpperCase(),
  )}${formattedDay.slice(1)}`

  return {
    dayOfWeek: moment.utc(value).utcOffset(timezone).format('dddd'), // 'Thứ Sáu'
    dayWithMonth: getFormattedDayWithMonth(value, timezone), // 'Thứ sáu, 28/07'
    shortDay, // 'T6, 28/07'
  }
}

// ISO => long weekday ("Thứ 4' or 'Wednesday")
export const getLongWeekdayFromIso = (
  value,
  timezone = '+07:00',
  lang = 'vi',
) => {
  moment.locale(lang)
  if (!value) return ''
  if (timezone === 'UTC') timezone = '+00:00'

  return moment.utc(value).utcOffset(timezone).format('dddd')
}

export const convertISODateTimeToLLLL = (
  value,
  timezone = '+07:00',
  locale,
) => {
  moment.locale(locale ?? getLocaleLanguage)
  if (!value) return ''
  return {
    date: moment.utc(value).utcOffset(timezone).format('L'),
    weekday: moment.utc(value).utcOffset(timezone).format('ddd'),
    dayAndMonth: moment.utc(value).utcOffset(timezone).format('DD/MM'),
  }
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}
// minutes => hours and mins:  150 => 2h30m
export function toHoursAndMinutes(time) {
  const minutesFullText = getLocaleLanguage === 'en' ? 'minutes' : 'phút'
  const minutesShortText = getLocaleLanguage === 'en' ? 'm' : 'p'
  const hourShortText = getLocaleLanguage === 'en' ? 'h' : 'h'

  const totalMinutes = Math.round(time)
  if (totalMinutes < 60) return `${padTo2Digits(totalMinutes)} ${minutesFullText}`
  const minutes = totalMinutes % 60
  const hours = Math.floor(totalMinutes / 60)
  return `${hours}${hourShortText}${padTo2Digits(minutes)}${minutesShortText}`
}

export function getTransitTime(departureTime, arrivalTime) {
  const departureMoment = moment(departureTime)
  const arrivalMoment = moment(arrivalTime)
  const duration = moment.duration(departureMoment.diff(arrivalMoment))
  const hours = Math.floor(duration.asHours())
  const minutes = Math.floor(duration.asMinutes()) % 60

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`
}

export function checkTransitAlert(arrival, departure) {
  const isNextAirport = departure.IATACode !== arrival.IATACode

  const arrivalMoment = moment(arrival.at)
  const departureMoment = moment(departure.at)
  const isNextDay = departureMoment.diff(arrivalMoment, 'days') !== 0

  const duration = moment.duration(departureMoment.diff(arrivalMoment))
  const hours = Math.floor(duration.asHours())
  const minutes = Math.floor(duration.asMinutes()) % 60
  const transitTime = `${hours}h${padTo2Digits(minutes)}m`

  return { isNextAirport, isNextDay, transitTime }
}

export function sendTimeNoti(date) {
  moment.locale('vi') // Đặt ngôn ngữ
  const time = moment(date).startOf('minute').fromNow()
  return time
}

// Format currency: 15000000 => 15.000.000
export const formatCurrency = value => {
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'VND',
  })

  return formatter.format(value).replace('₫', '').trim()
}

// Format Vietnamese currency: 1234567890.255 => 1.234.567.890,26
export const formatVnCurrency = (
  value,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
) => Number(value).toLocaleString('en', {
  minimumFractionDigits,
  maximumFractionDigits,
})

// get array date between startDate and endDate
export function getArrayDatesBetween(startDate, endDate) {
  const dateArray = []
  let currentDate = moment(startDate)
  const stopDate = moment(endDate)
  while (currentDate <= stopDate) {
    dateArray.push({ date: moment(currentDate).format('YYYY-MM-DD') })
    currentDate = moment(currentDate).add(1, 'days')
  }
  return dateArray
}

export const getMoment = isoTime => moment(isoTime)
export const currentTime = (timezone = '+07:00') => moment().utc(timezone).format('YYYY-MM-DDTHH:mm:SSZ')

export const convertShortTrip = (tripData, showIndex = true) => {
  if (tripData?.airline) {
    const departureCode = tripData.departure?.IATACode || tripData.departure?.iataCode || '_'
    const arrivalCode = tripData.arrival?.IATACode || tripData.arrival?.iataCode || '_'
    const data = {
      ...(showIndex && {
        index:
          tripData.segmentId?.length <= 3
            ? tripData.segmentId
            : tripData.devSegmentIndex || '',
      }),
      airlineFlightNumber: `${tripData.airline}${tripData.flightNumber}` || '',
      bookingClass: tripData.bookingClass || '',
      departureDate:
        convertISODateTime(
          tripData.departure?.at || tripData?.departureAt,
          tripData.departure?.timezone
            || tripData.departure?.timeZone
            || '+07:00',
        )?.dayMonth || '',
      itinerary: `${departureCode}${arrivalCode}`,
      status: tripData.status || '',
      departureTime:
        convertISODateTime(
          tripData.departure?.at || tripData?.departureAt,
          tripData.departure?.timezone
            || tripData.departure?.timeZone
            || '+07:00',
        )?.hourMin || '',
      arrivalTime:
        convertISODateTime(
          tripData.arrival?.at || tripData?.arrivalAt,
          tripData.arrival?.timezone || tripData.arrival?.timeZone || '+07:00',
        )?.hourMin || '',
    }
    const result = []
    for (const prop in data) {
      if (prop) result.push(data[prop])
    }
    return result.join(' ').toUpperCase()
  }
  return `${tripData?.segmentId || tripData} ARUNK`
}

export const getToday = (format = 'DD-MM-YYYY') => {
  if (!format) return moment().format()
  return moment().format(format)
}

// '01.01' => '1.1'
export const convertPaxIdToNotIncludesZero = paxId => {
  const paxSegment = paxId.split('.')
  return paxSegment.map(item => Number(item)).join('.')
}

export const replaceText = value => {
  if (value) {
    return value.replace('|', ' ')
  }
  return value
}

// Màu hãng
export function colorByAirline(airline) {
  let color = '#000'
  if (['VJ', 'VZ', 'AK'].includes(airline)) color = '#EC2029'
  if (airline === 'VU') color = '#FFC80B'
  if (['VN', '1S', '1S_CTRL', 'VN1A', 'VN1A_MT'].includes(airline)) color = '#166987'
  if (airline === 'QH') color = '#64AC54'
  if (['1G', 'TH', 'F1'].includes(airline)) color = '#000'
  return color
}
// Màu chữ các hãng
export function resolveBorderColorByAirline(airline, fw = 700) {
  let airlineStyle = `font-weight: ${fw};`
  airlineStyle += `color: ${colorByAirline(airline)} !important`
  return airlineStyle
}

// eslint-disable-next-line no-unused-vars
const businessClassVNA = ['I', 'D', 'C', 'J']

function resolveTripClasses(fareOption, source) {
  if (['1S', '1S_CTRL', 'VJ', 'VZ', 'QH'].includes(source)) {
    const isBusinessClass = fareOption?.groupClass?.toUpperCase().includes('BUSINESS')
      || businessClassVNA.includes(fareOption?.bookingClass)
    if (isBusinessClass) {
      return 'business'
    }
    return 'eco'
  }
  if (source.includes('VU')) {
    return 'all'
  }
  return 'all'
}

// filter theo groupFare
export function filterFareItem(item, fareOption, source) {
  let data = true
  const groupFare = resolveTripClasses(fareOption, source)
  if (groupFare === 'business') {
    data = item?.groupClass?.toUpperCase().includes('BUSINESS')
      || businessClassVNA.includes(item?.bookingClass)
  } else if (groupFare === 'eco') {
    data = !(
      item?.groupClass?.toUpperCase().includes('BUSINESS')
      || businessClassVNA.includes(item?.bookingClass)
    )
  } else if (groupFare === 'all') {
    data = true
  }
  return data
}

export function compareDateBySubtract(thisDate, subtract) {
  const now = moment()
  const dateItem = subtract.subtractValue
    ? moment(thisDate).subtract(subtract.subtractValue, subtract.subtractType)
    : moment(thisDate)

  const isBefore = dateItem.isBefore(now)
  const isSame = dateItem.isSame(now, subtract.subtractType)
  const isAfter = dateItem.isAfter(now)

  return {
    isBefore,
    isSame,
    isAfter,
  }
}

export function resolveAirlineFlightNumber(airline, flightNumber) {
  const airlineCodeNotShow = ['NOKAIR']

  const combinationAirline = airline?.split(' | ')?.length > 1
    && flightNumber?.split(' | ')?.length > 1
    && airline?.split(' | ')?.length === flightNumber?.split(' | ')?.length
  if (combinationAirline) {
    const airlineArr = airline?.split(' | ')
    const flightNumberArr = flightNumber?.split(' | ')

    const result = airlineArr.map(
      (airline, index) => `${airline}${flightNumberArr[index]}`,
    )
    return result.join(' | ')
  }

  return `${airlineCodeNotShow.includes(airline) ? '' : airline}${flightNumber}`
}

export function isGreaterThanOrEqualToNow(dateString) {
  const now = moment()
  const dateToCompare = moment(dateString)
  return dateToCompare.isSameOrAfter(now)
}

export function isDateGreaterThanOrEqualToToday(dateString) {
  const today = moment().startOf('day')
  const dateToCompare = moment(dateString).startOf('day')
  return dateToCompare.isSameOrAfter(today, 'day')
}

export function cutHonorificFromFirstName(firstNameMaybeIncludeHonorific) {
  const honorificRegex = /\b(MR|MRS|MS|MISS|MSTR)$/i
  const nameParts = firstNameMaybeIncludeHonorific.split(/\s+/)

  let firstName = ''
  let honorific = ''

  if (honorificRegex.test(nameParts[nameParts.length - 1])) {
    honorific = nameParts.pop()
  }

  firstName = nameParts.join(' ')

  return {
    firstName,
    honorific,
  }
}
