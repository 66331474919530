export default {
  title: 'Thông báo',
  sendNotification: 'Gửi thông báo',
  subject: 'Chủ đề',
  content: 'Nội dung',
  recipientRefType: 'Lọai người nhận',
  channel: 'Kênh',
  sendType: 'Loại gửi',
  reschedulePeriodInSecond: 'Thời gian lên lịch lại',
  sendTime: 'Thời gian gửi',
  deadline: 'Thời hạn',

  success: 'Thành công',
  error: 'Thất bại',
  sendNotificationSuccess: 'Gửi thông báo thành công',
  sendNotificationError: 'Gửi thông báo thất bại',
  sendTimeMustBeforeDeadline: 'Thời gian gửi phải trước thời hạn',
  confirmSCNote: 'Quý đại lý vui lòng gửi code cho booker kiểm tra lại thông tin.',
}
