import { isEmpty } from 'lodash'

import api, {
  apiDocument, apiBooking, apiPackageConfig, apiReservation,
} from '@/api/'

export default {
  namespaced: true,
  state: {
    config: {},
    allPins: [],
    distributors: {
      inland: [],
      international: [],
    },
    allDistributors: [], // no filter
    expiredPackage: [],
    allCountries: [],
  },
  getters: {
    isHasConfig: state => !isEmpty(state.config),
    getConfig: state => (configName = '') => {
      if (!configName) return state.config
      return state.config[configName]
    },
    getAirCraftByIata: state => code => {
      const result = state.config.airCraftIATA?.find(e => e.key === code)
      return result ? result.value : code
    },
    getAirlineNameByCode: state => code => {
      let result = null
      if (!state.config.airlines) return code
      result = state.config.airlines?.find(e => e.key === code)
      return result ? result.value : code
    },
    getFareRulesAirline: state => (airline, cabin) => {
      if (airline === '0V') airline = 'VN'
      let result = state.config.fareRulesAirline
      if (airline) {
        const value = (!localStorage.getItem('Locale') || localStorage.getItem('Locale') === 'en')
          ? 'value'
          : `${localStorage.getItem('Locale')}Value`
        result = result.find(item => item.key === airline)?.[value] || null
        if (cabin && result) {
          const getCabin = ['VN'].includes(airline) ? cabin.split(' | ')[0] : cabin
          result = result.find(
            item => item.cabin.toUpperCase() === getCabin.toUpperCase() || item.cabinName.toUpperCase() === getCabin.toUpperCase(),
          )
        }
      }
      const blankResult = {
        boardingBaggage: null,
        bookingClass: null,
        cabin: null,
        cabinName: null,
        checkinBaggage: null,
        checkinPriority: null,
        goShow: null,
        loungeService: null,
        refund: 0,
        reschedule: null,
        seat: null,
      }
      return result || blankResult
    },
    getFareRulesAirlineByBookingClass: state => (airline, key) => {
      if (airline === '0V') airline = 'VN'
      let bookingClass = null
      let cabin = null
      if (key && key.length > 0) cabin = key
      else bookingClass = key

      let result = state.config.fareRulesAirline
      if (airline && bookingClass) {
        // const value = localStorage.getItem('Locale') === 'en' ? 'value' : `${localStorage.getItem('Locale')}Value`
        result = result.find(item => item.key === airline)?.value || null
        if (bookingClass && result) {
          result = result.find(item => item?.bookingClass?.split(' | ')?.includes(bookingClass))
        }
      }
      if (airline && cabin) {
        const value = localStorage.getItem('Locale') === 'en'
          ? 'value'
          : `${localStorage.getItem('Locale')}Value`
        result = result.find(item => item.key === airline)?.[value] || null
        if (cabin && result) {
          result = result.find(item => {
            if (item.cabin.toUpperCase() === cabin.toUpperCase()) return true
            if (item.bookingClass.toUpperCase().includes(cabin.toUpperCase())) return true
            return false
          })
        }
      }
      const blankResult = {
        boardingBaggage: null,
        bookingClass: null,
        cabin: null,
        cabinName: null,
        checkinBaggage: null,
        checkinPriority: null,
        goShow: null,
        loungeService: null,
        refund: 0,
        reschedule: null,
        seat: null,
      }
      return result || blankResult
    },
    getBaggagesByBookingClass: state => (airline, bookingClass, groupClass) => {
      const result = {
        groupClass,
        boardingBaggage: '-',
        checkinBaggage: '-',
      }

      const fareRulesAirline = state.config.fareRulesAirline
      const valueKey = localStorage.getItem('Locale') === 'en'
        ? 'value'
        : `${localStorage.getItem('Locale')}Value`
      const arr = fareRulesAirline?.find(item => item.key === airline)?.[valueKey] || null
      if (!arr) return result

      const arrItem = arr.find(item => {
        const isGroupClass = groupClass && item.cabin && item.cabin?.toUpperCase() === groupClass.toUpperCase()
        const isBookingClass = bookingClass && item.bookingClass.includes(bookingClass)
        return isBookingClass || isGroupClass
      })
      if (!arrItem) return result

      result.groupClass = arrItem.cabin || groupClass
      result.boardingBaggage = arrItem.boardingBaggage
      result.checkinBaggage = arrItem.checkinBaggage
      return result
    },
    getCarryBag1G: state => airline => state.config.carryBag1G.find(item => item.key === airline)?.value || '7KG',
    findBank: state => text => {
      const getBankByCode = state.config.bankList?.find(item => item.key === text)
      if (getBankByCode) return getBankByCode
      const getBankByText = state.config.bankList?.find(item => [item.value.name, item.value.shortName, item.value.short_name].includes(text))
      return getBankByText
    },
    getBankList: state => state.config?.bankList || [],
    getErrorMessage: state => state.config?.errorMessage,
    getHistoryActionBookingByCode: state => code => {
      if (!state.config?.historyActionBooking) return code
      const historyAction = state.config?.historyActionBooking.find(item => item.key === code)
      const langValue = localStorage.getItem('Locale') === 'en' ? 'value' : `${localStorage.getItem('Locale')}Value`
      return historyAction?.[langValue] || code
    },
    getAlertSystem: state => state?.config?.alertSystem,
    getAllPins: state => state.allPins,
    getAllAlert: state => {
      const alertSystem = state?.config?.alertSystem || []
      return alertSystem.concat(state.allPins) || []
    },
    getLinkFareRules: state => source => state?.config?.linkFareRules.find(item => item.key === source)?.value || null,
    // distributors
    getDistributors: state => state.distributors,
    getAllDistributors: state => state.allDistributors, // get tất cả ko phân biệt nội địa/quốc tế, ko filter

    // package expired
    getExpiredPackage: state => state.expiredPackage,

    // show alert MFA when login
    getShowAlertMfaWhenLogin: state => (state?.config?.showAlert && state?.config?.showAlert.find(i => i.key === 'MFA_WHEN_LOGIN')?.value) || false,

    getAllCountries: state => state.allCountries,
  },
  mutations: {
    SET_GLOBAL_CONFIG(state, newConfig) {
      state.config = { ...newConfig }
    },
    SET_ALL_PINS(state, newAllPins) {
      state.allPins = newAllPins
    },
    SET_DISTRIBUTORS_DOMESTIC(state, val) {
      state.distributors.inland = val
    },
    SET_DISTRIBUTORS_INTERNATIONAL(state, val) {
      state.distributors.international = val
    },
    SET_ALL_DISTRIBUTORS(state, val) {
      state.allDistributors = val
    },
    SET_EXPIRED_PACKAGE(state, val) {
      state.expiredPackage = val
    },
    SET_ALL_COUNTRIES(state, val) {
      state.allCountries = val
    },
  },
  actions: {
    setGlobalConfig({ commit }) {
      api.general.getGlobalConfig()
        .then(response => {
          const { feConfigs } = response.data
          const mappingConfig = feConfigs.reduce((a, v) => {
            const arrayMap = a[v.category] || v.data
            const value = v.language === 'en' ? 'value' : `${v.language}Value`
            const resultArray = arrayMap.map(item => {
              let val = v.data.find(i => i.key === item.key)?.value
              try {
                val = JSON.parse(val)
              // eslint-disable-next-line no-empty
              } catch (e) {}
              return {
                ...item,
                [value]: val,
              }
            })
            return {
              ...a,
              [v.category]: resultArray,
            }
          }, {})
          commit('SET_GLOBAL_CONFIG', mappingConfig)
        })
        .catch(error => {
          console.error(error)
        })
    },
    getAllPins({ commit }) {
      apiDocument.getAllPins()
        .then(({ data }) => {
          const allPins = data.items.map(item => ({
            key: item.name, value: [''], id: item.id, priority: item.priorityPin,
          }))
          commit('SET_ALL_PINS', allPins)
        })
    },
    getAvailableDistributorSource({ commit, state }) {
      if (state.distributors.inland.length && state.distributors.international.length) return

      // eslint-disable-next-line consistent-return
      return apiBooking.getAvailableDistributorSource()
        .then(response => {
          commit('SET_ALL_DISTRIBUTORS', response.data.availableDistributor)
          commit('SET_DISTRIBUTORS_DOMESTIC', response.data.availableDistributor.filter(item => ['VN1A', 'QH', 'VJ', 'VU'].includes(item)))
          commit('SET_DISTRIBUTORS_INTERNATIONAL', response.data.availableDistributor.filter(item => !['VN1A_MT', '1S', '1S_CTRL'].includes(item)))
        })
    },

    fetchExpiredPackage({ commit }) {
      return apiPackageConfig.findAllExpiredAgencyPackageConfigs()
        .then(res => {
          commit('SET_EXPIRED_PACKAGE', res.data.items)
        })
    },
    clearExpiredPackage({ commit }) {
      commit('SET_EXPIRED_PACKAGE', [])
    },

    fetchAllCountries({ commit }) {
      return new Promise((resolve, reject) => {
        apiReservation.getCountriesName({ searchText: '', size: 999 })
          .then(response => {
            commit('SET_ALL_COUNTRIES', response.data.items)
            resolve(true)
          })
          .catch(error => reject(error))
      })
    },
  },
}
