export default {
  promotions: 'promotions',
  Create: 'Create',
  tableTitle: {
    checkbox: '',
    no: '#',
    code: 'Code',
    name: 'Name',
    discount: 'Discount',
    airline: 'Airline',
    source: 'Source',
    flightTrip: 'Flight trip',
    agencies: 'Agency',
    agencyApply: 'Agency',
    agenciesDeclined: 'Agency declined',
    applyTime: 'Apply Time',
    flightTime: 'Flight time',
    time: 'Time',
    minimumPassenger: 'Minimum Passenger',
    priority: 'Priority',
    flightTypes: 'Flight Types',
    flightTrips: 'Flight Trips',
    promotionType: 'Promotion Type',
    codeshares: 'Code Shares',
    quota: 'Quota',
    created: 'Created',
    updated: 'Updated',
    status: 'Status',
    action: 'Action',
  },
  filters: {
    dateType: 'Date type',
    from: 'From',
    to: 'To',
    airline: 'Airline',
  },

  discount: 'Discount',
  discountType: 'Discount Type',
  promotionType: 'Promotion Type',
  minimumPassenger: 'Minimum Passenger',
  quotaAmount: 'Quota Amount',
  quotaCounter: 'Quota Counter',
  code: 'Code',
  name: 'Name',
  airline: 'Airline',
  source: 'Source',
  trips: 'Trips',
  flightTrip: 'Flight Trip',
  countryTrips: 'Country Trip',
  inputflightTrip: 'Input new trip and press enter.',
  orSearchAirports:
    'Press the spacebar \u2423 or click the button to search for the airport',
  titleSearchAirports: 'Search departure airport and landing airport',
  addflightTrip: 'Add flight trip',
  flightTypes: 'Flight Types',
  agency: 'Agency',
  codeshares: 'Code Shares',
  operator: 'Operators',
  notExistCodeshare: 'Code share does not exist. Please enter another value.',
  bookingClass: 'Booking class',
  groupClass: 'Group class',
  countryDepartures: 'Country departures',
  countryArrivals: 'Country arrivals',
  fareBasisCode: 'Fare basis code',

  startTime: 'Start date',
  endTime: 'End date',
  flightStartTime: 'Flight start date',
  flightEndTime: 'Flight end date',
  promotionStrategy: 'Promotion Strategy',

  create: {
    title: 'Create New Promotion AG',
  },
  edit: {
    title: 'Detail promotion AG',
    titleEdit: 'Edit promotion AG',
  },
  delete: {
    confirm: 'Are you sure to delete promotion AG?',
  },

  placeholder: {
    discountAmount: 'Enter amount discount',
    discountPercent: 'Enter % discount',
    quotaAmount: 'Enter quota amount',
    quotaCounter: 'Enter quota counter',
    code: 'Enter code',
    flightTrip: 'Example: HAN-SGN\u23CE HAN-DAD\u23CE',
    countryTrips: 'Example: AE-SG\u23CE AU-HA\u23CE',
    bookingClass: 'Enter booking class',
    groupClass: 'Enter group class',
    fareBasisCode: 'Enter fare basis code',
    name: 'Enter name',
    minimumPassenger: 'Enter minimum passenger',
    codeshares: 'Select or enter Code Shares',
    operator: 'Select or enter operator',
    selectOrInput: 'Select or input',
  },

  allAgencies: 'All agencies',
  allAirlines: 'All airlines',
  allSources: 'All sources',

  PREPAID: 'Prepaid',
  POSTPAID: 'Postpaid',

  BY_CUSTOMER_SEGMENT: 'By customer / segment',
  BY_TICKET_NUMBER: 'By ticket number',
}
