import { getOsEnv } from './utils'

const isTPV = getOsEnv('VUE_APP_CODE') === 'TPV'

export const env = {
  isProduction: getOsEnv('VUE_APP_ENV') === 'production',
  isStaging: getOsEnv('VUE_APP_ENV') === 'staging',
  isDevelopment: getOsEnv('VUE_APP_ENV') === 'development',

  // BANNER
  bannerFull: getOsEnv('VUE_APP_BANNER_FULL_URL'),
  bannerMobile: getOsEnv('VUE_APP_BANNER_MOBILE_URL'),
  logoNoti: getOsEnv('VUE_APP_LOGO_NOTI_URL'),
  logoFavicon: getOsEnv('VUE_APP_LOGO_FAVICON_URL'),
  logoFull: getOsEnv('VUE_APP_LOGO_FULL_URL'),

  // GENERAL
  appName: getOsEnv('VUE_APP_NAME') || 'Airdata',
  mamaShortName: getOsEnv('VUE_APP_MAMA_SHORT_NAME') || getOsEnv('VUE_APP_NAME') || 'ADT',
  mamaFullName: getOsEnv('VUE_APP_MAMA_FULL_NAME') || getOsEnv('VUE_APP_NAME'),

  // config for TPV
  isTPV,
  isShowPaymentCardLast: isTPV, // 2. Di chuyển block chi tiết giá xuống phía dưới dịch vụ bổ sung
  isHideResell: isTPV,
  isCombineFullName: isTPV || true, // Gộp field họ tên
}

export default env
