import service from './service'

export default {
  // Package configs
  findAllPackageConfigs(data) {
    return service.get('/package_configs', data)
  },
  updatePackageConfigs(id, payload = {}) {
    return service.put(`/package_configs/${id}`, payload)
  },
  createPackageConfigs(payload) {
    return service.post('/package_configs/bulk-insert', payload)
  },

  // Agency package configs
  findAllAgencyPackageConfigs(data, history = false) {
    return service.get(`/agency_package_configs${history ? '/history' : ''}`, data)
  },
  findByPeriod(id) {
    return service.get(`/agency_package_configs/history/${id}`)
  },
  findAllExpiredAgencyPackageConfigs(data) {
    return service.get('/agency_package_configs/expires', data)
  },
  updateAgencyPackageConfigs(id, payload = {}) {
    return service.put(`/agency_package_configs/${id}`, payload)
  },
  buyPackage(payload) {
    return service.post('/agency_package_configs/buy-package', payload)
  },
  giftPackages(payload) {
    return service.post('/agency_package_configs/gift-package', payload)
  },

  // Agency package order
  findAllAgencyPackageOrder(data) {
    return service.get('/agency_package_orders', data)
  },
  cancelAgencyPackageOrder(id) {
    return service.put(`/agency_package_orders/${id}/cancel`)
  },

  // Coupon
  fetchAllPackageCoupons(params) {
    return service.get('/package_coupons', params)
  },

  createPackageCoupons(payload) {
    return service.post('/package_coupons', payload)
  },

  updatePackageCoupons(id, payload) {
    return service.put(`/package_coupons/${id}`, payload)
  },

  deletePackageCoupons(id) {
    return service.delete(`/package_coupons/${id}`)
  },

  getCouponsByCode(code) {
    return service.get(`/package_coupons/${code}`)
  },
}
