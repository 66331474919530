export default {
  pageTitle: 'Electronic Ticket Template',
  sectionsTitle: {
    config: 'Electronic ticket template configs',
    template: 'Electronic ticket template',
  },
  showHideConfig: 'Show/ hide ticket config',
  agencyInfo: 'Agency information',
  showBackground: 'Show background',
  price: 'Price',
  holdTime: 'Hold time',
  ticketStatus: 'Booking status',
  bookingClass: 'Booking class',
  useEditor: 'Use Editor',
  paymentInfo: 'QR code payment information',
  qrBookingInfo: 'QR booking information',
  priceNetFare: 'Price + Fee and tax',
  priceDetail: 'Price detail',
  showAncillaries: 'Show ancillaries',
  showBackgroundColor: 'Background color',
  bankAccount: 'Bank account',
  phBankAccount: 'Select bank account',
  language: 'Language',
  color: 'Color',
  colors: {
    yellow: 'Yellow',
    red: 'Red',
    green: 'Green',
    blue: 'Blue',
    lightBlue: 'Light Blue',
    black: 'Black',
    pink: 'Pink',
    indigo: 'Indigo',
    purple: 'Purple',
    orange: 'Orange',
    teal: 'Teal',
    gray: 'Gray',
  },
  eTicket: 'Electronic ticket',
  prepaymentNote: 'Complete your payment by: 6:30 pm on 14/06/2023',
  payBefore: 'Complete your payment by',
  clickToChangeStatus: 'Click to change the status of the ticket to',
  statusHold: 'HOLD',
  statusCancel: 'CANCEL',
  statusPaid: 'PAID',
  flightsInfo: {
    bookingID: 'PNR Code',
    trip1: '(Wednesday) 21/06/2023 Ha Noi - Ho Chi Minh',
    trip2: '(Monday) 28/06/2023 Ho Chi Minh - Ha Noi',
    departTime: 'Departure Time',
    arrivalTime: 'Arrival Time',
    terminal: 'Terminal',
    codeshareWith: 'Codeshare with',
    aircraft: 'Aircraft',
    distance: 'Distance (Miles)',
    duration: 'Duration',
    handBaggage: 'Carry on baggage',
    freeBaggage: 'Checked baggage',

    flight: 'Flight',
    transitIn: 'Transit in',
    transitTime: 'Transit time',
  },
  na: 'N/A',
  pnrCodes: {
    // https://support.travelport.com/webhelp/uapi/Content/Air/Shared_Air_Topics/PNR_Status_Codes.htm
    HK: 'Holds confirmed',
  },
  paxTable: {
    no: 'No.',
    passenger: 'Passenger Name',
    additionalServices: 'Addon Service',
    pax1: '(Mr) VIET DUNG TRAN',
    pax2: '(Mstr) MINH DUC NGUYEN',
    pax3: '(Miss) LE PHUONG TRAN',
    bagsTicket1: 'SGN - HAN: 23KG checked baggage',
    bagsTicket2: 'SGN - HAN: 23KG checked baggage',
    bagsTicket3: '',
  },
  ticketPrice: 'Fare Details',
  ticketAncillaries: 'Ancillaries',
  priceTable: {
    passenger: 'Passenger',
    fare: 'Fare',
    tax: 'Taxes and fees',
    total: 'Total',
    totalOfPrice: 'Total of Price',
  },
  note: {
    title: 'Note',
    content1:
      'Passengers need to arrive at the airport early to have enough time for check-in (at least 90 minutes). Check-in counter closes at least 60 minutes. Paying change fee or non-refundable your ticket or need to make a new booking and purchase a new ticket at the airport.',
    content2:
      "Travel document are the original copies and are still valid: Identity card (not valid over 15 years); Driver's license; Passport; Communist Party of Vietnam membership card; National Assembly membership card.",
    content3:
      'Passengers under the age of 14 (years): Birth Certificate must be the original or a copy certified by a notarial office with legal status.',
    content4:
      'Passengers from the age of 14 (years): Certifying personal identification form by local police or authorities citing passenger‘s permanent residence or temporary residence. Above certifying forms must be attached with stamped portrait picture and shall be valid within 30 days from the date of confirmation.',
    content5:
      'Passengers is infants under one [01] month old: Birth Certifying Form.',
    content6:
      'PASSENGERS MUST BE PRESENT AS AIRPORT CHECK-IN COUNTERS AT LEAST 90 MINUTES BEFORE THE DEPARTURE OF DOMESTIC FLIGHTS',
  },
  saveTicketConfigSuccess: 'Save ticket configs successfully.',
  saveTicketConfigErr: 'Save ticket configs not successfully.',
  exportTicketSuccess: 'Export PDF ticket face successfully.',
  exportTicketgErr: 'Export PDF ticket face not successfully.',
  pack: 'PC',
  oversized: 'oversized',
  ticketAlertWarning: {
    1: 'This is the ticket for information exchange between the agency and the customer.',
    2: 'The information is not 100% accurate for booking on the airline. Agency please check before sending to customers.',
  },
}
