export default {
  sell: 'Sell ticket',
  posted: 'Posted',
  agreement: 'Agreement',
  create: 'Create',
  form: {
    titleSetting: 'Resell ticket',
    title: 'Resell ticket',
    price: 'Price',
    phone: 'Phone number',
    note: 'Note',
    notePrivate: 'Note private',
    ticketNumber: 'Ticket number',
    agencyCode: 'Agency code',
    type: 'Type',
    expiredDate: 'Deadline for sale',
    reason: 'Reason',
    source: 'Source',
    status: 'Status',
    ph: {
      price: 'Enter price',
      phone: 'Enter phone number',
      note: 'Enter note',
      notePrivate: 'Enter note private',
      ticketNumber: 'Enter ticket number',
      agencyCode: 'Enter agency code',
      type: 'Enter type',
      reason: 'Enter reason',
    },
  },
  columns: {
    stt: 'No.',
    no: 'No.',
    booker: 'Agency',
    type: 'Type',
    paxName: 'Pax Name',
    paxList: 'List Pax',
    paxType: 'Pax Type',
    flightDates: 'Flight Date',
    priceTicket: 'Price Ticket',
    price: 'Price',
    phone: 'Phone',
    note: 'Note',
    source: 'Source',
    actions: 'Actions',
    notePrivate: 'Note private',
    isActive: 'Active',
    expiredDate: 'Expired date',
    ticketNumber: 'Amount ticket',
    pnr: 'PNR',
    publishDate: 'Publish Date',
    airlineCode: 'Airline',
    modifiedBy: 'Modified By',
    updatedAt: 'Modified At',
    newValue: 'New value',
    oldValue: 'Old value',
  },
  createSuccess: 'Sale registration successful',
  deleteSuccess: 'De-identification successful',
  updateSuccess: 'ID ticket update successful',
  createReportSuccess: 'Report successful',
  updateStatusSuccess: 'ID ticket status update successful',
  redirect: 'Do you want to go to the refund ticket market?',
  btn: {
    call: 'Call',
    edit: 'Edit',
    add: 'Add',
    delete: 'Delete',
    report: 'Report',
    deactive: 'Deactive',
    active: 'Active',
    pushlishNow: 'Pushlish now',
    reportDetail: 'Report Detail',
    logsDetail: 'Audit logs',
    ban: 'Ban',
    unban: 'Unban',
    editNote: 'Edit note',
  },
  type: {
    IDENTITY: 'Identity reservation',
    AGENT: 'Credit shell refund',
  },
  status: {
    UNPUBLIC: 'Unpublic',
    PUBLIC: 'Public',
    SOLD: 'Sold',
  },
  note: {
    title: 'Note',
    note1: 'For any feedback, please contact SALES.',
    note2:
      'After a successful transaction, please “temporarily lock” or “delete” the post.',
    note3:
      'In all cases of reported sale posts, Technical Support will lock the poster’s shop feature.',
    paxName: 'Enter full name, do not enter gender.',
    price: "If not entered price, it will display as 'negotiable'.",
    agencyCode: 'From which primary agency? Optional.',
    pnrs: 'Note: Actual reserved refund may differ if Adult has "accompanying infant"',
  },
  report: {
    warning1: '(*) Absolutely no trial usage (no testing).',
    warning2:
      '(**) There will be 2 rules applied to this violation report (complaint):',
    report1:
      'If you report falsely, your identification feature will be locked.',
    report2:
      'The person correctly reported will have their identification feature locked',
    total: 'Total',
    totalID: 'Total identity reservation',
    totalAG: 'Total agent reservation',
    details: 'Report detail',
  },
  expired: 'Expired Date',
  notFoundId: 'Not found ticket',
  banTo: 'Ban to',
  reported: 'Reported',
  unReported: 'Un reported',
}
