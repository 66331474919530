export default {
  promotions: 'hoa hồng',
  Create: 'Thêm mới',
  tableTitle: {
    checkbox: '',
    no: '#',
    code: 'Mã code',
    name: 'Tên chương trình',
    discount: 'Giảm giá',
    airline: 'Hãng',
    source: 'Hệ thống đặt vé',
    flightTrip: 'Hành trình',
    agencies: 'Đại lý',
    applyTime: 'Thời gian áp dụng',
    flightTime: 'Thời gian bay',
    time: 'Thời gian áp dụng',
    minimumPassenger: 'Số lượng hành khách tối thiếu',
    priority: 'Độ ưu tiên',
    flightTypes: 'Loại hành trình',
    flightTrips: 'Hành trình',
    promotionType: 'Loại chiết khấu',
    codeshares: 'Code Shares',
    quota: 'Hạn ngạch',
    created: 'Thời gian tạo',
    updated: 'Cập nhật cuối',
    status: 'Trạng thái',
    action: 'Thao tác',
  },
  filters: {
    dateType: 'Loại ngày',
    from: 'Từ ngày',
    to: 'Đến ngày',
    airline: 'Hãng',
  },

  discount: 'Giảm giá',
  discountType: 'Loại giảm giá',
  promotionType: 'Loại chiết khấu',
  minimumPassenger: 'Số lượng hành khách tối thiếu',
  quotaAmount: 'Định mức tối đa',
  quotaCounter: 'Định mức số phiếu giảm giá tối đa',
  code: 'Mã giảm giá',
  name: 'Tên chương trình',
  airline: 'Hãng hàng không',
  source: 'Hệ thống đặt vé',
  trips: 'Chặng bay',
  flightTrip: 'Hành trình',
  countryTrips: 'Các quốc gia',
  inputflightTrip: 'Nhập hành trình và nhấn enter.',
  orSearchAirports:
    'Nhấn phím cách \u2423 hoặc bấm vào nút để tìm kiếm sân bay',
  titleSearchAirports: 'Tìm kiếm sân bay khởi hành và sân bay hạ cánh',
  addflightTrip: 'Thêm hành trình',
  flightTypes: 'Loại hành trình',
  agency: 'Đại lý áp dụng',
  agencyApply: 'Đại lý áp dụng',
  agenciesDeclined: 'Đại lý loại trừ',
  codeshares: 'Code Shares',
  operator: 'Hãng vận hành',
  notExistCodeshare: 'Code share không tồn tại. Hãy nhập vào giá trị khác.',
  bookingClass: 'Hạng vé',
  groupClass: 'Nhóm vé',
  countryDepartures: 'Điểm đi',
  countryArrivals: 'Điểm đến',
  fareBasisCode: 'Fare basis code',

  startTime: 'Thời gian xuất vé áp dụng bắt đầu',
  endTime: 'Thời gian xuất vé áp dụng kết thúc',
  flightStartTime: 'Thời gian bay áp dụng bắt đầu',
  flightEndTime: 'Thời gian bay áp dụng kết thúc',
  promotionStrategy: 'Chiến lược',

  create: {
    title: 'Thêm mới hoa hồng AG',
  },
  edit: {
    title: 'Chi tiết hoa hồng AG',
    titleEdit: 'Cập nhật hoa hồng AG',
  },
  delete: {
    confirm: 'Bạn có chắc chắn xoá hoá hồng AG?',
  },

  placeholder: {
    discountAmount: 'Điền vào số tiền giảm giá',
    discountPercent: 'Điền vào số % giảm giá',
    quotaAmount: 'Điền vào số tiền',
    quotaCounter: 'Điền vào số lượng',
    code: 'Điền vào mã giảm giá',
    flightTrip: 'Ví dụ: HAN-SGN\u23CE HAN-DAD\u23CE',
    countryTrips: 'Ví dụ: AE-SG\u23CE AU-HA\u23CE',
    bookingClass: 'Điền vào Hạng vé',
    groupClass: 'Điền vào Nhóm vé',
    fareBasisCode: 'Điền vào Fare basis code',
    name: 'Điền vào tên chương trình',
    minimumPassenger: 'Điền vào số lượng hành khách tối thiếu',
    codeshares: 'Lựa chọn hoặc nhập vào Code Shares',
    operator: 'Lựa chọn hoặc nhập vào operator',
    selectOrInput: 'Lựa chọn hoặc nhập vào',
  },

  allAgencies: 'Tất cả các đại lý',
  allAirlines: 'Tất cả các hãng',
  allSources: 'Tất cả các hệ thống',

  PREPAID: 'Thanh toán trước',
  POSTPAID: 'Thanh toán sau',

  BY_CUSTOMER_SEGMENT: 'Theo hành khách / chặng',
  BY_TICKET_NUMBER: 'Theo số vé',
}
