export default {
  alpha: '{_field_} chỉ có thể chứa các kí tự chữ',
  alpha_dash:
    '{_field_} có thể chứa các kí tự chữ (A-Z a-z), số (0-9), gạch ngang (-) và gạch dưới (_)',
  alpha_num: '{_field_} chỉ có thể chứa các kí tự chữ và số',
  alpha_spaces: '{_field_} chỉ có thế chứa các kí tự và khoảng trắng',
  between: '{_field_} phải có giá trị nằm trong khoảng giữa {min} và {max}',
  confirmed: '{_field_} không khớp',
  digits:
    'Trường {_field_} chỉ có thể chứa các kí tự số và bắt buộc phải có độ dài là {length}',
  dimensions:
    '{_field_} phải có chiều rộng {width} pixels và chiều cao {height} pixels',
  double: '{_field_} phải là một số thập phân hợp lệ',
  email: '{_field_} phải là một địa chỉ email hợp lệ',
  excluded: '{_field_} không phải là một giá trị hợp lệ',
  ext: '{_field_} phải là một tệp',
  image: 'Trường {_field_} phải là một ảnh',
  integer: 'Trường {_field_} phải là một số nguyên',
  length: '{_field_} cần phải có độ dài {length}',
  max: '{_field_} không thể có nhiều hơn {length} kí tự',
  max_value: '{_field_} phải nhỏ hơn hoặc bằng {max}',
  mimes: '{_field_} phải chứa kiểu tệp phù hợp',
  min: '{_field_} phải chứa ít nhất {length} kí tự',
  min_value: '{_field_} phải lớn hơn hoặc bằng {min}',
  numeric: '{_field_} chỉ có thể có các kí tự số',
  oneOf: '{_field_} không phải là một giá trị hợp lệ',
  regex: '{_field_} có định dạng không đúng',
  required: '{_field_} là bắt buộc. Vui lòng chọn hoặc nhập vào',
  required_if: '{_field_} là bắt buộc',
  size: '{_field_} chỉ có thể chứa tệp nhỏ hơn {size}KB',
  phoneNumber: '{_field_} có định dạng không đúng',
  url: '{_field_} có định dạng không đúng',
  code: '{_field_} có định dạng không đúng. {_field_} phải là một chuỗi 9 kí tự bao gồm chữ in hoa và số.',
  warningCredit: 'Số dư cảnh báo phải nhỏ hơn số dư giới hạn',
  limitCredit: 'Số dư giới hạn phải lớn hơn số dư cảnh báo',
  maximumCredit: 'Số dư giới hạn phải nhỏ hơn giới hạn tối đa',
  validateBookerId: '{_field_} phải bắt đầu bằng BKVN...',
  bookerId: 'ID Booker',

  Email: 'Email',
  Password: 'Mật khẩu',
  newPassword: 'Mật khẩu mới',
  oldPassword: 'Mật khẩu cũ',
  retypePassword: 'Xác nhận mật khẩu',
  confirmation: 'Mật khẩu xác nhận',
  username: 'Tên đăng nhập',
  firstName: 'Tên đệm & Tên',
  lastName: 'Họ',
  isUnique: '{_field_} đã được sử dụng. Vui lòng nhập lại.',
  phone: 'Số điện thoại',
  agencyName: 'Tên đại lý',
  agencyOwner: 'Người đại diện đại lý',
  agencyManager: 'Quản lý đại lý',
  agencyWarningCredit: 'Số dư cảnh báo', // phải nhỏ hơn số dư giới hạn
  agencyLimitCredit: 'Số dư giới hạn', // phải lớn hơn số dư cảnh báo
  sendMonthReport: 'Gửi báo cáo hàng tháng',
  userName:
    '{_field_} sai định dạng. {_field_} phải bắt đầu là chữ. Vui lòng thử lại!',
  status: 'Trạng thái',
  dob: 'Ngày sinh',
  identityCardNumber: 'Số căn cước công dân ',

  attachRoleForUserSuccessful: 'Thêm vai trò cho nhân viên thành công.',
  attachRoleForUserError: 'Lỗi thêm vai trò cho nhân viên.',
  detachRoleForUserSuccessful: 'Xoá vai trò cho nhân viên thành công.',
  detachRoleForUserError: 'Lỗi xoá vai trò cho nhân viên.',

  addAgencyToListManagementDuplicated: 'Đại lý bạn chọn đã có trong danh sách!',
  addAgencyToListManagementSuccess: 'Thêm đại lý vào danh sách thành công!',
  addAgencyToListManagementError: 'Lỗi thêm đại lý vào danh sách',

  successful: 'Hành động đã thành công!',
  title: 'Thành công',

  // ANCHOR Login Logout
  loginSuccessful: 'Đăng nhập thành công!',
  loginError: 'Lỗi đăng nhập',
  logoutSuccessful: 'Đăng xuất thành công!',
  welcome: 'Chào mừng',
  loginInfoIncorrect:
    'Tên đăng nhập hoặc mật khẩu không đúng. Vui lòng nhập lại.',
  accountDisabled: 'Tài khoản bị khoá.',
  auth: {
    passwordChanged: 'Đổi mật khẩu thành công',
    PASSWORD_MUST_NOT_BE_THE_OLD_PASSWORD:
      'Mật khẩu mới phải khác với mật khẩu cũ',
    INVALID_PASSWORD: 'Mật khẩu cũ không đúng',
    // forgot password
    emailSent:
      'Mã OTP đã được gửi đến địa chỉ email của bạn. Vui lòng kiểm tra và làm theo hướng dẫn chi tiết.',
    EMAIL_NOT_FOUND: 'Không tìm thấy email trong hệ thống.',
    otpSuccess: 'Nhập mã OTP thành công',
    INVALID_OTP: 'Mã OTP không đúng',
  },
  'Departure and destination must be difference':
    'Điểm khởi hành và điểm đến không được giống nhau!',
  'Invalid date, please try again': 'Chọn ngày không đúng, vui lòng chọn lại',
  'Please select departure / destination': 'Vui lòng chọn điểm đi / điểm đến',
  'Please select departure date / arrival date':
    'Vui lòng chọn ngày đi / ngày đến',

  success: 'Thành công!',
  warning: 'Cảnh báo!',
  error: 'Lỗi!',
  errAction:
    'There was an error when making it, please check the information again!',

  employee: {
    updatedStatusSuccess: 'Cập nhật trạng thái thành công!',
    updatedStatusError: 'Lỗi cập nhật trạng thái',
    errorFetching: 'Lỗi tải danh sách',
    createSuccess: 'Tạo nhân viên thành công',
    createError: 'Lỗi tạo nhân viên',
    updateSuccess: 'Cập nhật nhân viên thành công',
    updateError: 'Lỗi cập nhật nhân viên',
    deleteSuccess: 'Xoá nhân viên thành công',
    deleteError: 'Lỗi xoá nhân viên',
    exportSuccess: 'Xuất excel thành công',
    exportError: 'Lỗi xuất excel',
    exportSampleSuccess: 'Xuất excel mẫu thành công',
    exportSampleError: 'Lỗi xuất excel mẫu',
    importSuccess: 'Nhập excel thành công',
    importError: 'Lỗi nhập excel',
  },
  agency: {
    edited: 'Cập nhật thành công',
    added: 'Tạo thành công',
    deleted: 'Xoá thành công',
    deactived: 'Khoá hoạt động thành công',
    actived: 'Kích hoạt thành công',
    exported: 'Xuất excel thành công',
    addBankAccountsSuccess: 'Thêm tài khoản ngân hàng thành công',
    addBankAccountsError: 'Lỗi thêm tài khoản ngân hàng',
    sendSuccess: 'Yêu cầu email test thành công',
  },
  flight: {
    createBookingSuccessful: 'Giữ chỗ thành công',
    paynowBookingSuccessful: 'Thanh toán ngay thành công',
    multiCityMustBeDomestic:
      'Các chuyến bay nhiều chặng chỉ hỗ trợ các chuyến bay nội địa. Vui lòng sử dụng chức năng "Hạng vé" VN để tìm kiếm.',
    errCheck: 'Vui lòng kiểm tra lại và nhập đầy đủ thông tin!',
    errMaxLengthPax: 'Thông tin hành khách quá dài',
    errMaxLengthPaxContent:
      'Thông tin hành khách có độ dài vượt quá quy định của hãng',
    errMissingDoB:
      'Vui lòng không để trống năm sinh của hành khách nếu thêm thông tin Passport',
    uAccountCannotPayNow:
      'Tài khoản đại lý của bạn không có quyền xuất vé ngay. Vui lòng liên hệ đại lý cấp trên.',
  },
  promotionAg: {
    fetchPromotionError: 'Lỗi tải danh sách hoa hồng AG',
    createPromotionSuccess: 'Tạo hoa hồng AG thành công',
    createPromotionError: 'Lỗi tạo hoa hồng AG',
    updatePromotionSuccess: 'Cập nhật hoa hồng AG thành công',
    updatePromotionError: 'Lỗi cập nhật hoa hồng AG',
    deletePromotionSuccess: 'Xoá hoa hồng AG thành công',
    deletePromotionError: 'Lỗi xoá hoa hồng AG',
  },

  workSchedule: {
    fetchScheduleError: 'Lỗi tải danh sách lịch trực',
    createScheduleSuccess: 'Tạo lịch trực thành công',
    createScheduleError: 'Lỗi tạo lịch trực',
  },

  reservation: {
    modifyDocumentsSuccess: 'Sửa thông tin hộ chiếu thành công',
    modifyDocumentsError: 'Lỗi sửa thông tin hộ chiếu',
    voidTicketSuccess: 'Void vé thành công',
    voidTicketError: 'Lỗi void vé',
    getCommissionsError: 'Lỗi tải danh sách commissions',
    refundTicketsSuccess: 'Hoàn vé thành công',
    refundTicketError: 'Lỗi hoàn vé',
    exchangeTicketsSuccess: 'Đổi vé thành công',
    exchangeTicketError: 'Lỗi đổi vé',
    modifyRemarksSuccess: 'Gửi ghi chú thành công',
    modifyRemarksError: 'Lỗi gửi ghi chú',
    shortenBookingError: 'Lỗi tải vé rút gọn',
    shortenEticketError: 'Lỗi tải số vé rút gọn',
    sendQueuesSuccess: 'Gửi queues thành công',
    sendQueuesError: 'Lỗi gửi queues',
    confirmSegmentsSuccess: 'Xác nhận chỗ thành công',
    confirmSegmentsError: 'Lỗi xác nhận chỗ',
    osiFlightSuccess: 'OSI thành công',
    osiFlightError: 'Lỗi OSI',
    bookSpecialServicesSuccess: 'Đặt dịch vụ đặc biệt thành công',
    bookSpecialServicesError: 'Lỗi đặt dịch vụ đặc biệt',
    outDateExchangeTicketErrorTitle: 'Quá hạn đổi vé VNA',
    outDateExchangeTicketError:
      'Chỉ được thay đổi vé VNA với hành trình có thời gian khởi hành trước 3 tiếng so với hiện tại.',
    modifyFrequentFlyerSuccess: 'Thêm thẻ thành viên thành công',
    modifyFrequentFlyerError: 'Lỗi thêm thẻ thành viên, vui lòng kiểm tra lại',
    recalculatePriceSuccess: 'Tính lại giá thành công',
    recalculatePriceError: 'Lỗi tính lại giá',
    saveRecalculatePriceSuccess: 'Lưu giá mới thành công',
    saveRecalculatePriceError: 'Lỗi lưu giá mới',
  },

  saleReport: {
    createManually: {
      success: 'Tạo báo cáo bán thủ công thành công!',
      error: 'Lỗi tạo báo cáo bán thủ công',
    },
    deleteManually: {
      success: 'Xoá báo cáo bán thủ công thành công!',
      error: 'Lỗi xoá báo cáo bán thủ công',
    },
    updateManually: {
      success: 'Chỉnh sửa báo cáo bán thành công!',
      error: 'Lỗi chỉnh sửa báo cáo bán',
    },
    fetchListError: 'Lỗi tải danh sách báo cáo bán',
  },

  accountPayable: {
    fetchListError: 'Lỗi tải danh sách công nợ',
    fetchDetailError: 'Lỗi tải chi tiết công nợ',
    updateSaleReportSuccess: 'Cập nhật báo cáo bán thành công',
    updateSaleReportError: 'Lỗi cập nhật báo cáo bán',
    bookClosingSuccess: 'Đóng sổ thành công',
    bookClosingError: 'Lỗi đóng sổ',
    exportSuccess: 'Xuất excel thành công',
    exportError: 'Lỗi xuất excel',
  },

  settingAccount: {
    editBankAccountError: 'Lỗi sửa tài khoản ngân hàng',
    editBankAccountSuccess: 'Sửa tài khoản ngân hàng thành công',

    deleteBankAccountSuccess: 'Xoá tài khoản ngân hàng thành công',
    deleteBankAccountError: 'Lỗi xoá tài khoản ngân hàng',

    verifyBankAccountSuccess: 'Xác thực tài khoản ngân hàng thành công',
    verifyBankAccountError: 'Lỗi xác thực tài khoản ngân hàng',

    resendBankAccountSuccess: 'Gửi mã xác thực tài khoản ngân hàng thành công',
    resendBankAccountError: 'Lỗi gửi mã xác thực tài khoản ngân hàng',

    setDefaultBankSuccess: 'Cài đặt tài khoản mặc định thành công',
    setAutoBankSuccess: 'Xác thực giao dịch tự động thành công',
    setDefaultBankError: 'Lỗi cài đặt tài khoản mặc định. Vui lòng thử lại',
    cantUnSetDefaultBank: 'Hãy chọn một tài khoản ngân hàng khác làm mặc định',
    cantSetDefaultBank: 'Tài khoản ngân hàng chưa xác thực',

    switchDefault: 'Đổi tài khoản mặc định thành công',
  },
}
