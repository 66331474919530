/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import service from './service'

export default {
  getBanks() {
    return service.get('/topup/banks')
  },

  getBankHolder(gateway, bankNumber) {
    return service.get('/bank-accounts/bank-holder', { gateway, bankNumber })
  },

  getBankAccountsByAgencyId(agencyId) {
    return service.get(`/bank-accounts/agency/${agencyId}`)
  },

  getBankAccounts() {
    return service.get('/bank-accounts')
  },

  addBankAccount(payload) {
    return service.post('/bank-accounts', payload)
  },

  removeBankAccount(id) {
    return service.delete(`/bank-accounts/${id}`)
  },

  resendOTP(id) {
    return service.post(`/bank-accounts/${id}/resend-otp`)
  },

  verifyBankAccount(id, payload) {
    return service.post(`/bank-accounts/${id}/verify`, payload)
  },

  setDefaultBank(id) {
    return service.post(`/bank-accounts/${id}/set-default`)
  },
  setAutoBank(id) {
    return service.post(`/bank-accounts/${id}/set-auto`)
  },
}
