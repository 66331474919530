// import { computed } from '@vue/composition-api'

// import store from '@/store'

// const meData = computed(() => store.getters['userStore/getMeData'])
import { env } from '@/libs/env'

export default [
  // {
  //   path: '/apps/calendar',
  //   name: 'apps-calendar',
  //   component: () => import('@/views/apps/calendar/Calendar.vue'),
  // },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/email',
  //   name: 'apps-email',
  //   component: () => import('@/views/apps/email/Email.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'email-application',
  //   },
  // },
  // {
  //   path: '/apps/email/:folder',
  //   name: 'apps-email-folder',
  //   component: () => import('@/views/apps/email/Email.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'email-application',
  //     navActiveLink: 'apps-email',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (
  //       ['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)
  //     ) next()
  //     else next({ name: 'error-404' })
  //   },
  // },
  // {
  //   path: '/apps/email/label/:label',
  //   name: 'apps-email-label',
  //   component: () => import('@/views/apps/email/Email.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'email-application',
  //     navActiveLink: 'apps-email',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (
  //       ['personal', 'company', 'important', 'private'].includes(
  //         to.params.label,
  //       )
  //     ) next()
  //     else next({ name: 'error-404' })
  //   },
  // },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/todo',
  //   name: 'apps-todo',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //   },
  // },

  // {
  //   path: '/apps/todo/:filter',
  //   name: 'apps-todo-filter',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //     navActiveLink: 'apps-todo',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },

  // {
  //   path: '/apps/todo/tag/:tag',
  //   name: 'apps-todo-tag',
  //   component: () => import('@/views/apps/todo/Todo.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'todo-application',
  //     navActiveLink: 'apps-todo',
  //   },
  //   beforeEnter(to, _, next) {
  //     if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
  //     else next({ name: 'error-404' })
  //   },
  // },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/chat',
  //   name: 'apps-chat',
  //   component: () => import('@/views/apps/chat/Chat.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left',
  //     contentClass: 'chat-application',
  //   },
  // },
  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/e-commerce/shop',
  //   name: 'apps-e-commerce-shop',
  //   component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
  //   meta: {
  //     contentRenderer: 'sidebar-left-detached',
  //     contentClass: 'ecommerce-application',
  //     pageTitle: 'Shop',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Shop',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/e-commerce/wishlist',
  //   name: 'apps-e-commerce-wishlist',
  //   component: () => import(
  //     '@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'
  //   ),
  //   meta: {
  //     pageTitle: 'Wishlist',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Wishlist',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/e-commerce/checkout',
  //   name: 'apps-e-commerce-checkout',
  //   component: () => import(
  //     '@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'
  //   ),
  //   meta: {
  //     pageTitle: 'Checkout',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Checkout',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/e-commerce/:slug',
  //   name: 'apps-e-commerce-product-details',
  //   component: () => import(
  //     '@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'
  //   ),
  //   meta: {
  //     pageTitle: 'Product Details',
  //     contentClass: 'ecommerce-application',
  //     breadcrumb: [
  //       {
  //         text: 'ECommerce',
  //       },
  //       {
  //         text: 'Shop',
  //         active: true,
  //       },
  //       {
  //         text: 'Product Details',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // *===============================================---*
  // ANCHOR: ROLE
  // *===============================================---*
  {
    path: '/apps/roles',
    name: 'apps-role-list',
    meta: {
      pageTitle: 'Roles',
      icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'Role management',
          active: true,
          to: { name: 'apps-role-list' },
        },
        // {
        //   text: 'Create New',
        //   active: true,
        // },
      ],
      // FIXME action: 'authz.getCustomRole',
    },
    component: () => import('@/views/apps/role/index.vue'),
  },

  // {
  //   path: '/apps/role/add',
  //   name: 'apps-role-add',
  //   meta: {
  //     pageTitle: 'Roles',
  //     icon: 'UserPlusIcon',
  //     breadcrumb: [
  //       {
  //         text: 'Role management',
  //         active: false,
  //         to: { name: 'apps-role-list' },

  //       },
  //       {
  //         text: 'Create new',
  //         to: { name: 'apps-role-add' },
  //         active: true,
  //       },
  //     ],
  //   },
  //   component: () => import('@/views/apps/role/create.vue'),
  // },

  // *===============================================---*
  // ANCHOR: SALE REPORT
  // *===============================================---*
  {
    path: '/apps/sale-report',
    name: 'apps-saleReport',
    meta: {
      pageTitle: 'Sale Report',
      icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'Sale Report',
          active: true,
          to: { name: 'apps-saleReport' },
        },
      ],
      action: 'sales_report.readSalereport',
    },
    component: () => import('@/views/apps/sale-report/SaleReport.vue'),
  },

  {
    path: '/apps/sale-report/create-manual',
    name: 'apps-saleReport-create-manual',
    component: () => import('@/views/apps/sale-report/SaleReportCreateManually.vue'),
    meta: {
      pageTitle: 'Create sale report manually',
      breadcrumb: [
        {
          text: 'Sale Report',
          to: { name: 'apps-saleReport' },
        },
        {
          text: 'Create sale report manually',
          active: true,
        },
      ],
      // action: 'sales_report.importSalereport',
      floor: ['F1'], // F của user
      role: ['ADM', 'KTT'], // type của user
      hideForProduction: true,
    },
  },
  {
    path: '/apps/sale-report/detail/:id',
    name: 'apps-saleReport-detail',
    component: () => import('@/views/apps/sale-report/SaleReportDetail.vue'),
    meta: {
      pageTitle: 'Sale report detail',
      breadcrumb: [
        {
          text: 'Sale Report',
          to: { name: 'apps-saleReport' },
        },
        {
          text: 'Sale report detail',
          active: true,
        },
      ],
      hideForProduction: true,
      // action: 'sales_report.importSalereport',
    },
  },

  {
    path: '/apps/sale-report/import',
    name: 'apps-saleReport-import',
    component: () => import('@/views/apps/sale-report/SaleReportImport.vue'),
    meta: {
      pageTitle: 'Import accounts payable',
      // icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'Accounts payable list',
          to: { name: 'apps-saleReport' },
        },
        {
          text: 'Import accounts payable',
          active: true,
        },
      ],
      action: 'sales_report.importSalereport',
    },
  },

  {
    path: '/apps/sale-report/refund-ticket',
    name: 'apps-saleReportRefundTicket',
    meta: {
      pageTitle: 'Sale Report Refund Ticket',
      // icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'Sale Report',
          to: { name: 'apps-saleReport' },
        },
        {
          text: 'Sale Report Refund Ticket',
          active: true,
          to: { name: 'apps-saleReportRefundTicket' },
        },
      ],
      action: 'sales_report.readSalereport',
    },
    component: () => import(
      '@/views/apps/sale-report/refund-ticket/SaleReportRefundTicketList.vue'
    ),
  },

  // *===============================================---*
  // ANCHOR: Resell Ticket
  // *===============================================---*

  {
    path: '/apps/marketplace',
    name: 'apps-resell-ticket',
    meta: {
      floor: ['F1', 'F2'],
      pageTitle: 'Resell Ticket',
      // icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'Sale Report',
          to: { name: 'apps-saleReport' },
        },
        {
          text: 'Resell Ticket',
          active: true,
          to: { name: 'apps-resell-ticket' },
        },
      ],
      hideForProduction: env.isHideResell,
    },
    component: () => import('@/views/apps/sale-report/resell-ticket/ResellTicketList.vue'),
  },
  {
    path: '/apps/manager-marketplace',
    name: 'apps-manager-resell-ticket',
    meta: {
      floor: ['F1', 'F2'],
      pageTitle: 'Manager Resell Ticket',
      // icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'Resell Ticket',
          to: { name: 'apps-resell-ticket' },
        },
        {
          text: 'Manager Resell Ticket',
          active: true,
          to: { name: 'apps-manager-resell-ticket' },
        },
      ],
      hideForProduction: env.isHideResell,
    },
    component: () => import('@/views/apps/sale-report/resell-ticket/ResellTicketList.vue'),
  },

  {
    path: '/apps/marketplace/:id',
    name: 'apps-detail-resell-ticket',
    meta: {
      floor: ['F1', 'F2'],
      pageTitle: 'Resell Ticket',
      // icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'Resell Ticket',
          active: true,
          to: { name: 'apps-resell-ticket' },
        },
      ],
      hideForProduction: env.isHideResell,
    },
    component: () => import('@/views/apps/sale-report/resell-ticket/DetailResellTicket.vue'),
  },
  // *===============================================---*
  // ANCHOR: Support Request
  // *===============================================---*

  {
    path: '/apps/supports',
    name: 'apps-supports',
    meta: {
      floor: ['F1', 'F2'],
      pageTitle: 'Support Request',
      // icon: 'UserPlusIcon',
    },
    component: () => import('@supportRequest/list/SupportReqList.vue'),
  },
  {
    path: '/apps/supports/:id',
    name: 'apps-support-detail',
    meta: {
      floor: ['F1', 'F2'],
      pageTitle: 'Support Request Detail',
      // icon: 'UserPlusIcon',
    },
    component: () => import('@supportRequest/detail/SupportReqDetail.vue'),
  },
  // *===============================================---*
  // ANCHOR: EMPLOYEE
  // *===============================================---*
  {
    path: '/apps/employees/list',
    name: 'apps-employees-list',
    component: () => import('@/views/apps/employee/employees-list/EmployeesList.vue'),
    meta: {
      pageTitle: 'Employee management',
      breadcrumb: [
        {
          text: 'Employee List',
          active: true,
        },
      ],
      action: 'employee.listEmployee',
    },
  },
  {
    path: '/apps/employees/add',
    name: 'apps-employees-add',
    component: () => import('@/views/apps/employee/employees-add/EmployeesAdd.vue'),
    meta: {
      pageTitle: 'Create New Employee',
      icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'Employee management',
          to: { name: 'apps-employees-list' },
        },
        {
          text: 'Create New',
          active: true,
        },
      ],
      action: 'employee.createEmployee',
    },
  },
  {
    path: '/apps/employees/edit/:id',
    name: 'apps-employees-edit',
    component: () => import('@/views/apps/employee/employees-edit/EmployeesEdit.vue'),
    meta: {
      pageTitle: 'Employee detail',
      icon: 'EditIcon',
      breadcrumb: [
        {
          text: 'Employee management',
          to: { name: 'apps-employees-list' },
        },
        {
          text: 'Employee detail',
          active: true,
        },
      ],
      action: 'employee.detailEmployee',
    },
  },

  // *===============================================---*
  // ANCHOR: CUSTOMERS
  // *===============================================---*
  {
    path: '/apps/customers/list',
    name: 'apps-customers-list',
    component: () => import('@/views/apps/customer/customers-list/CustomersList.vue'),
    meta: {
      pageTitle: 'Customer management',
      breadcrumb: [
        {
          text: 'Customer List',
          active: true,
        },
      ],
      action: 'customer.listCustomer',
    },
  },
  {
    path: '/apps/customers/add',
    name: 'apps-customers-add',
    component: () => import('@/views/apps/customer/customers-add/CustomersAdd.vue'),
    meta: {
      pageTitle: 'Create New Customer',
      icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'Customer management',
          to: { name: 'apps-customers-list' },
        },
        {
          text: 'Create New Customer',
          active: true,
        },
      ],
      action: 'customer.createCustomer',
    },
  },
  {
    path: '/apps/customers/edit/:id',
    name: 'apps-customers-edit',
    component: () => import('@/views/apps/customer/customers-edit/CustomersEdit.vue'),
    meta: {
      pageTitle: 'Customer detail',
      icon: 'EditIcon',
      breadcrumb: [
        {
          text: 'Customer management',
          to: { name: 'apps-customers-list' },
        },
        {
          text: 'Customer detail',
          active: true,
        },
      ],
      action: 'customer.detailCustomer',
    },
  },
  // {
  //   path: '/apps/customers/edit/:id/contacts/add',
  //   name: 'apps-customers-edit-contacts-add',
  //   component: () => import('@/views/apps/customer/customers-edit/contacts/ContactAdd.vue'),
  //   meta: {
  //     pageTitle: 'Contact Add',
  //     icon: 'EditIcon',
  //     breadcrumb: [
  //       {
  //         text: 'Customer management',
  //         to: { name: 'apps-customers-list' },
  //       },
  //       {
  //         text: 'Customer detail',
  //         to: { name: 'apps-customers-edit' },
  //       },
  //       {
  //         text: 'Contacts',
  //         to: { name: 'apps-customers-edit' },
  //       },
  //       {
  //         text: 'Contact Add',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/customers/edit/:id/contacts/:contactId',
  //   name: 'apps-customers-edit-contacts-edit',
  //   component: () => import('@/views/apps/customer/customers-edit/contacts/ContactEdit.vue'),
  //   meta: {
  //     pageTitle: 'Contact Edit',
  //     icon: 'EditIcon',
  //     breadcrumb: [
  //       {
  //         text: 'Customer management',
  //         to: { name: 'apps-customers-list' },
  //       },
  //       {
  //         text: 'Customer detail',
  //         to: { name: 'apps-customers-edit' },
  //       },
  //       {
  //         text: 'Contacts',
  //         to: { name: 'apps-customers-edit' },
  //       },
  //       {
  //         text: 'Contact Edit',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // *===============================================---*
  // ANCHOR: Register List
  // *===============================================---*
  // {
  //   path: '/apps/reg/list',
  //   name: 'apps-reg-list',
  //   component: () => import('@/views/apps/agencies/register-list/index.vue'),
  //   meta: {
  //     pageTitle: 'Register Management',
  //     breadcrumb: [
  //       {
  //         text: 'Register List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // *===============================================---*
  // ANCHOR: RETAIL
  // *===============================================---*

  {
    path: '/apps/retail',
    name: 'apps-retailGeneral',
    component: () => import('@/views/apps/retail/index.vue'),
    floor: ['F2'],
    meta: {
      pageTitle: 'Retail Report',
      breadcrumb: [
        {
          text: 'Retail Report',
          active: true,
        },
      ],
      floor: ['F2'],
      role: ['ADM', 'OPE'],
    },
    beforeEnter(to, _, next) {
      const employeeData = JSON.parse(localStorage.getItem('employeeData'))
      if (employeeData?.agency?.enableRetail) next()
      else next({ name: 'misc-not-authorized' })
    },
  },
  {
    path: '/apps/retail/report-bookings',
    name: 'apps-retailBookings',
    component: () => import('@/views/apps/retail/RetailReportBookings.vue'),
    meta: {
      pageTitle: 'Retail Bookings',
      breadcrumb: [
        {
          text: 'Retail Report',
          to: { name: 'apps-retailGeneral' },
        },
        {
          text: 'Retail Report',
          active: true,
        },
      ],
      floor: ['F2'],
      role: ['ADM', 'OPE'],
    },
    beforeEnter(to, _, next) {
      const employeeData = JSON.parse(localStorage.getItem('employeeData'))
      if (employeeData?.agency?.enableRetail) next()
      else next({ name: 'misc-not-authorized' })
    },
  },
  {
    path: '/apps/retail/accounts-payable/list',
    name: 'apps-retailAccountsPayable-list',
    component: () => import(
      '@/views/apps/retail/accounts-payable/list/RetailAccountsPayableList.vue'
    ),
    meta: {
      pageTitle: 'Account Payable Retail',
      breadcrumb: [
        {
          text: 'Account Payable Retail',
          active: true,
        },
      ],
      floor: ['F2'],
      role: ['ADM', 'OPE'],
    },
    beforeEnter(to, _, next) {
      const employeeData = JSON.parse(localStorage.getItem('employeeData'))
      if (employeeData?.agency?.enableRetail) next()
      else next({ name: 'misc-not-authorized' })
    },
  },
  {
    path: '/apps/retail/accounts-payable/detail',
    name: 'apps-retailAccountsPayable-detail',
    component: () => import(
      '@/views/apps/retail/accounts-payable/detail/RetailAccountsPayableDetail.vue'
    ),
    meta: {
      pageTitle: 'Account Payable Retail',
      breadcrumb: [
        {
          text: 'Account Payable Retail',
          active: true,
        },
      ],
      floor: ['F2'],
      role: ['ADM', 'OPE'],
    },
    beforeEnter(to, _, next) {
      const employeeData = JSON.parse(localStorage.getItem('employeeData'))
      if (employeeData?.agency?.enableRetail) next()
      else next({ name: 'misc-not-authorized' })
    },
  },
  {
    path: '/apps/retail/sale-reports/list',
    name: 'apps-retailSaleReports',
    component: () => import('@/views/apps/retail/sale-report/RetailSaleReportList.vue'),
    meta: {
      pageTitle: 'Sale Report Retail',
      breadcrumb: [
        {
          text: 'Sale Report Retail',
          active: true,
        },
      ],
      floor: ['F2'],
      role: ['ADM', 'OPE'],
    },
    beforeEnter(to, _, next) {
      const employeeData = JSON.parse(localStorage.getItem('employeeData'))
      if (employeeData?.agency?.enableRetail) next()
      else next({ name: 'misc-not-authorized' })
    },
  },
  {
    path: '/apps/retail/configs/service-fee',
    name: 'apps-retailConfigsServiceFee',
    component: () => import('@/views/apps/configs/service-fee/ServiceFee.vue'),
    meta: {
      pageTitle: 'Retail Config Service Fee',
      breadcrumb: [
        {
          text: 'Retail Config Service Fee',
          active: true,
        },
      ],
      floor: ['F2'],
      role: ['ADM', 'OPE'],
    },
    beforeEnter(to, _, next) {
      const employeeData = JSON.parse(localStorage.getItem('employeeData'))
      if (employeeData?.agency?.enableRetail) next()
      else next({ name: 'misc-not-authorized' })
    },
  },

  // *===============================================---*
  // ANCHOR: AGENCIES
  // *===============================================---*
  {
    path: '/apps/agencies/list',
    name: 'apps-agencies-list',
    component: () => import('@/views/apps/agencies/agencies-list/AgenciesList.vue'),
    meta: {
      pageTitle: 'Agency Management',
      breadcrumb: [
        {
          text: 'Agency List',
          active: true,
        },
      ],
      action: 'agency.listAgency',
      floor: ['F1', 'F2'],
    },
  },

  {
    path: '/apps/agencies/list/warning_over_percentage',
    name: 'apps-agencies_warning_over_percentage-list',
    component: () => import('@/views/apps/agencies/agencies-list/AgenciesList.vue'),
    meta: {
      pageTitle: 'Agency Management',
      breadcrumb: [
        {
          text: 'Agency List',
          active: true,
        },
      ],
      action: 'agency.listAgency',
      floor: ['F1', 'F2'],
    },
  },
  {
    path: '/apps/agencies/add',
    name: 'apps-agencies-add',
    component: () => import('@/views/apps/agencies/agencies-add/AgenciesAdd.vue'),
    meta: {
      pageTitle: 'Create New Agency',
      icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'Agency Management',
          to: { name: 'apps-agencies-list' },
        },
        {
          text: 'Create New Agency',
          active: true,
        },
      ],
      action: 'agency.createAgency',
      floor: ['F1', 'F2'],
    },
  },
  // {
  //   path: '/apps/agencies/view/:id',
  //   name: 'apps-agencies-view',
  //   component: () => import('@/views/apps/agencies/agencies-view/AgenciesView.vue'),
  // },
  {
    path: '/apps/agencies/edit/:id',
    name: 'apps-agencies-edit',
    component: () => import('@/views/apps/agencies/agencies-edit/AgenciesEdit.vue'),
    meta: {
      pageTitle: 'Agency Detail',
      icon: 'EditIcon',
      breadcrumb: [
        {
          text: 'Agency Management',
          to: { name: 'apps-agencies-list' },
        },
        {
          text: 'Agency Detail',
          active: true,
        },
      ],
      action: 'agency.detailAgency',
      floor: ['F1', 'F2'],
    },
  },

  // *===============================================---*
  // ANCHOR: TOP UP MANAGERMENT
  // *===============================================---*
  // recipient
  {
    path: '/apps/topup/list/recipient',
    name: 'apps-topupRecipient-list',
    component: () => import('@/views/apps/topup/topup-list/TopupList.vue'),
    meta: {
      pageTitle: 'topup.management',
      breadcrumb: [
        {
          text: 'topup.list',
          active: true,
        },
      ],
      action: 'topup.listTopup',
    },
  },
  {
    path: '/apps/topup/list/recipient/pending_payment',
    name: 'apps-topupRecipient_pending-list',
    component: () => import('@/views/apps/topup/topup-list/TopupList.vue'),
    meta: {
      pageTitle: 'topup.management',
      breadcrumb: [
        {
          text: 'topup.list',
          active: true,
        },
      ],
      action: 'topup.listTopup',
    },
  },
  {
    path: '/apps/topup/list/recipient/done',
    name: 'apps-topupRecipient_done-list',
    component: () => import('@/views/apps/topup/topup-list/TopupList.vue'),
    meta: {
      pageTitle: 'topup.management',
      breadcrumb: [
        {
          text: 'topup.list',
          active: true,
        },
      ],
      action: 'topup.listTopup',
    },
  },
  // sender
  {
    path: '/apps/topup/list/sender',
    name: 'apps-topupSender-list',
    component: () => import('@/views/apps/topup/topup-list/TopupList.vue'),
    meta: {
      pageTitle: 'topup.management',
      breadcrumb: [
        {
          text: 'topup.list',
          active: true,
        },
      ],
      action: 'topup.listTopup',
    },
  },
  {
    path: '/apps/topup/list/sender/pending_payment',
    name: 'apps-topupSender_pending-list',
    component: () => import('@/views/apps/topup/topup-list/TopupList.vue'),
    meta: {
      pageTitle: 'topup.management',
      breadcrumb: [
        {
          text: 'topup.list',
          active: true,
        },
      ],
      action: 'topup.listTopup',
    },
  },
  {
    path: '/apps/topup/list/sender/done',
    name: 'apps-topupSender_done-list',
    component: () => import('@/views/apps/topup/topup-list/TopupList.vue'),
    meta: {
      pageTitle: 'topup.management',
      breadcrumb: [
        {
          text: 'topup.list',
          active: true,
        },
      ],
      action: 'topup.listTopup',
    },
  },
  // detail
  {
    path: '/apps/topup/code/:code',
    name: 'apps-topup-view-code',
    component: () => import('@/views/apps/topup/topup-view/TopupViewByCode.vue'),
    meta: {
      pageTitle: 'topup.detail',
      icon: 'EditIcon',
      breadcrumb: [
        {
          text: 'topup.detail',
          active: true,
        },
      ],
      action: 'topup.detailTopup',
    },
  },
  // detail
  {
    path: '/apps/topup/view/:id',
    name: 'apps-topup-view',
    component: () => import('@/views/apps/topup/topup-view/TopupView.vue'),
    meta: {
      pageTitle: 'topup.detail',
      icon: 'EditIcon',
      breadcrumb: [
        {
          text: 'topup.detail',
          active: true,
        },
      ],
      action: 'topup.detailTopup',
    },
  },

  // *===============================================---*
  // ANCHOR: ERROR NOTI MANAGERMENT
  // *===============================================---*
  {
    path: '/apps/error-noti',
    name: 'apps-error-noti-list',
    meta: {
      pageTitle: 'errorNoti.list',
      icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'errorNoti.management',
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/error-noti/error-noti-list/index.vue'),
  },

  {
    path: '/apps/error-noti/detail/:id',
    name: 'apps-error-noti-detail',
    component: () => import('@/views/apps/error-noti/error-noti-detail/ErrorNotiDetail.vue'),
    meta: {
      pageTitle: 'errorNoti.pageTitle.detail',
      breadcrumb: [
        {
          text: 'errorNoti.management',
          to: { name: 'apps-error-noti-list' },
        },
        {
          text: 'errorNoti.pageTitle.detail',
          active: true,
        },
      ],
    },
  },

  {
    path: '/apps/error-noti/update/:id',
    name: 'apps-error-noti-update',
    component: () => import('@/views/apps/error-noti/error-noti-update/ErrorNotiUpdate.vue'),
    meta: {
      pageTitle: 'errorNoti.pageTitle.update',
      breadcrumb: [
        {
          text: 'errorNoti.management',
          to: { name: 'apps-error-noti-list' },
        },
        {
          text: 'errorNoti.pageTitle.update',
          active: true,
        },
      ],
    },
  },

  {
    path: '/apps/error-noti/add',
    name: 'apps-error-noti-add',
    component: () => import('@/views/apps/error-noti/error-noti-add/errorNotiAdd.vue'),
    meta: {
      pageTitle: 'errorNoti.pageTitle.add',
      breadcrumb: [
        {
          text: 'errorNoti.management',
          to: { name: 'apps-error-noti-list' },
        },
        {
          text: 'errorNoti.pageTitle.add',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // ANCHOR: INVOICES
  // *===============================================---*
  {
    path: '/apps/invoices/list',
    name: 'apps-invoiceList',
    component: () => import('@/views/invoices/list/InvoiceList.vue'),
    meta: {
      pageTitle: 'Invoice List',
      breadcrumb: [
        {
          text: 'Invoice List',
          active: true,
        },
      ],
      // action: 'invoice.listInvoice',
      floor: ['F1', 'F2'],
      hideForProduction: true,
    },
  },
  {
    path: '/apps/invoices/create',
    name: 'apps-invoiceCreate',
    component: () => import('@/views/invoices/create/InvoiceCreate.vue'),
    meta: {
      pageTitle: 'Invoice Create',
      breadcrumb: [
        {
          text: 'Invoice Create',
          active: true,
        },
      ],
      // action: 'invoice.listInvoice',
      floor: ['F1', 'F2'],
      hideForProduction: true,
    },
  },
  {
    path: '/apps/invoices/detail/:id',
    name: 'apps-invoiceDetail',
    component: () => import('@/views/invoices/detail/InvoiceDetail.vue'),
    meta: {
      pageTitle: 'Invoice Detail',
      breadcrumb: [
        {
          text: 'Invoice Detail',
          active: true,
        },
      ],
      // action: 'invoice.listInvoice',
      floor: ['F1', 'F2'],
      hideForProduction: true,
    },
  },
  {
    path: '/apps/invoices/replace/:id',
    name: 'apps-invoiceReplace',
    component: () => import('@/views/invoices/detail/InvoiceDetail.vue'),
    meta: {
      pageTitle: 'Invoice Replace',
      breadcrumb: [
        {
          text: 'Invoice Replace',
          active: true,
        },
      ],
      // action: 'invoice.listInvoice',
      floor: ['F1', 'F2'],
      hideForProduction: true,
    },
  },
  {
    path: '/apps/invoices/adjust/:id',
    name: 'apps-invoiceAdjust',
    component: () => import('@/views/invoices/detail/InvoiceDetail.vue'),
    meta: {
      pageTitle: 'Invoice Adjust',
      breadcrumb: [
        {
          text: 'Invoice Adjust',
          active: true,
        },
      ],
      // action: 'invoice.listInvoice',
      floor: ['F1', 'F2'],
      hideForProduction: true,
    },
  },
  {
    path: '/apps/invoices/preview/:id',
    name: 'apps-invoicePreview',
    component: () => import('@/views/invoices/preview/InvoicePreview.vue'),
    meta: {
      pageTitle: 'Invoice Preview',
      layout: 'full',
      floor: ['F1', 'F2'],
      hideForProduction: true,
    },
  },

  // *===============================================---*
  // ANCHOR: INVOICE MANAGEMENT OLD
  // *===============================================---*
  // {
  //   path: '/apps/invoicesOld/list',
  //   name: 'apps-invoicesOld-list',
  //   component: () => import('@/views/apps/invoices/invoice-list/InvoiceMultipleLists.vue'),
  //   meta: {
  //     pageTitle: 'invoice.list',
  //     breadcrumb: [
  //       {
  //         text: 'invoice.management',
  //         active: true,
  //       },
  //     ],
  //     action: 'invoice.listInvoice',
  //     floor: ['F1', 'F2'],
  //   },
  // },
  // {
  //   path: '/apps/invoicesOld/view/:id',
  //   name: 'apps-invoicesOld-view',
  //   component: () => import('@/views/apps/invoices/invoice-view/InvoiceView.vue'),
  //   meta: {
  //     pageTitle: 'invoice.detail',
  //     breadcrumb: [
  //       {
  //         text: 'invoice.management',
  //         to: { name: 'apps-invoicesOld-list' },
  //       },
  //       {
  //         text: 'invoice.detail',
  //         active: true,
  //       },
  //     ],
  //     action: 'invoice.detailInvoice',
  //     floor: ['F1', 'F2'],
  //   },
  // },
  // {
  //   path: '/apps/invoicesOld/add',
  //   name: 'apps-invoicesOld-add',
  //   component: () => import('@/views/apps/invoices/invoice-edit/InvoiceEdit.vue'),
  //   meta: {
  //     pageTitle: 'invoice.pageTitle.add',
  //     breadcrumb: [
  //       {
  //         text: 'invoice.management',
  //         to: { name: 'apps-invoicesOld-list' },
  //       },
  //       {
  //         text: 'invoice.pageTitle.add',
  //         active: true,
  //       },
  //     ],
  //     action: 'invoice.createInvoice',
  //     floor: ['F1', 'F2'],
  //     path: 'add',
  //   },
  // },
  // {
  //   path: '/apps/invoicesOld/update/:id',
  //   name: 'apps-invoicesOld-update',
  //   component: () => import('@/views/apps/invoices/invoice-edit/InvoiceEdit.vue'),
  //   meta: {
  //     pageTitle: 'invoice.pageTitle.update',
  //     breadcrumb: [
  //       {
  //         text: 'invoice.management',
  //         to: { name: 'apps-invoicesOld-list' },
  //       },
  //       {
  //         text: 'invoice.pageTitle.update',
  //         active: true,
  //       },
  //     ],
  //     action: 'invoice.updateInvoice',
  //     floor: ['F1', 'F2'],
  //     path: 'update',
  //   },
  // },
  // {
  //   path: '/apps/invoicesOld/adjust/:id',
  //   name: 'apps-invoicesOld-adjust',
  //   component: () => import('@/views/apps/invoices/invoice-edit/InvoiceEdit.vue'),
  //   meta: {
  //     pageTitle: 'invoice.pageTitle.adjust',
  //     breadcrumb: [
  //       {
  //         text: 'invoice.management',
  //         to: { name: 'apps-invoicesOld-list' },
  //       },
  //       {
  //         text: 'invoice.pageTitle.adjust',
  //         active: true,
  //       },
  //     ],
  //     action: 'invoice.adjustInvoice',
  //     floor: ['F1', 'F2'],
  //     path: 'adjust',
  //   },
  // },
  // {
  //   path: '/apps/invoicesOld/replace/:id',
  //   name: 'apps-invoicesOld-replace',
  //   component: () => import('@/views/apps/invoices/invoice-edit/InvoiceEdit.vue'),
  //   meta: {
  //     pageTitle: 'invoice.pageTitle.replace',
  //     breadcrumb: [
  //       {
  //         text: 'invoice.management',
  //         to: { name: 'apps-invoicesOld-list' },
  //       },
  //       {
  //         text: 'invoice.pageTitle.replace',
  //         active: true,
  //       },
  //     ],
  //     action: 'invoice.substituteInvoice',
  //     floor: ['F1', 'F2'],
  //     path: 'replace',
  //   },
  // },

  // *===============================================---*
  // ANCHOR: AUDIT LOG MANAGERMENT
  // *===============================================---*
  {
    path: '/apps/audit-log/list',
    name: 'apps-audit-log-list',
    component: () => import('@/views/apps/audit-log/audit-log-list/index.vue'),
    meta: {
      pageTitle: 'auditLog.management',
      breadcrumb: [
        {
          text: 'auditLog.list',
          active: true,
        },
      ],
      floor: ['F1'],
      role: ['ADM', 'OPE'],
    },
  },

  // *===============================================---*
  // *--------- receipt ---- ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/receipt/list',
  //   name: 'apps-receipt-list',
  //   component: () => import('@/views/apps/receipt/receipt-list/ReceiptList.vue'),
  //   meta: {
  //     pageTitle: 'Receipt Management',
  //     breadcrumb: [
  //       {
  //         text: 'Receipt List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/receipt/add',
  //   name: 'apps-receipt-add',
  //   component: () => import('@/views/apps/receipt/receipt-add/ReceiptAdd.vue'),
  //   meta: {
  //     pageTitle: 'Receipt Management',
  //     breadcrumb: [
  //       {
  //         text: 'Receipt Add',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // // *===============================================---*
  // // *--------- receipts ---- ---------------------------------------*
  // // *===============================================---*
  // {
  //   path: '/apps/receipts',
  //   name: 'apps-receipts-list',
  //   component: () => import('@/views/apps/receipts/receipt-list/ReceiptList.vue'),
  //   meta: {
  //     pageTitle: 'Receipt Management',
  //     breadcrumb: [
  //       {
  //         text: 'Receipt List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/receipts/add',
  //   name: 'apps-receipts-add',
  //   component: () => import('@/views/apps/receipts/receipt-add/ReceiptAdd.vue'),
  //   meta: {
  //     pageTitle: 'Create New Receipt',
  //     icon: 'FilePlusIcon',
  //     breadcrumb: [
  //       {
  //         text: 'Receipt Management',
  //         to: { name: 'apps-receipts-list' },
  //       },
  //       {
  //         text: 'Create New',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/receipts/detail/:id',
  //   name: 'apps-receipts-detail',
  //   component: () => import('@/views/apps/receipts/receipt-detail/ReceiptDetail.vue'),
  //   meta: {
  //     pageTitle: 'Receipt Detail',
  //     icon: 'FileTextIcon',
  //     breadcrumb: [
  //       {
  //         text: 'Receipt Management',
  //         to: { name: 'apps-receipts-list' },
  //       },
  //       {
  //         text: 'Receipt Detail',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/receipts/edit/:id',
  //   name: 'apps-receipts-edit',
  //   component: () => import('@/views/apps/receipts/receipt-edit/ReceiptEdit.vue'),
  //   meta: {
  //     pageTitle: 'Edit Receipt',
  //     icon: 'EditIcon',
  //     breadcrumb: [
  //       {
  //         text: 'Receipt Management',
  //         to: { name: 'apps-receipts-list' },
  //       },
  //       {
  //         text: 'Receipt Detail',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // *===============================================---*
  // *--------- document ---- ---------------------------------------*
  // *===============================================---*
  // {
  //   path: '/apps/document/list',
  //   name: 'apps-document-list',
  //   component: () => import('@/views/apps/document/Document.vue'),
  //   meta: {
  //     pageTitle: 'Document Management',
  //     breadcrumb: [
  //       {
  //         text: 'Document List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/apps/document/add',
  //   name: 'apps-document-add',
  //   component: () => import('@/views/apps/document/Document.vue'),
  // },
  // {
  //   path: '/apps/document/view/:id',
  //   name: 'apps-document-view',
  //   component: () => import('@/views/apps/receipt/receipt-view/AgenciesView.vue'),
  // },
  // {
  //   path: '/apps/document/edit/:id',
  //   name: 'apps-document-edit',
  //   component: () => import('@/views/apps/receipt/receipt-edit/AgenciesEdit.vue'),
  // },

  // *===============================================---*
  // *--------- ANCHOR: documents ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/documents/folder-view',
    name: 'apps-documents-folder-view',
    component: () => import('@/views/apps/documents/document-folder/DocumentViewFolder.vue'),
    meta: {
      pageTitle: 'Folder list',
      breadcrumb: [
        {
          text: 'Folder list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/documents/folder-tree',
    name: 'apps-documents-folder-tree',
    component: () => import('@/views/apps/documents/document-folder/DocumentTreeFolder.vue'),
    meta: {
      pageTitle: 'Document Management',
      breadcrumb: [
        {
          text: 'Document Tree',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/documents/:id/list',
    name: 'apps-documents-list',
    component: () => import('@/views/apps/documents/document-list/DocumentList.vue'),
    meta: {
      pageTitle: 'Document Management',
      icon: 'FileTextIcon',
      breadcrumb: [
        {
          text: 'Folder list',
          to: { name: 'apps-documents-folder-view' },
        },
        {
          text: 'Document list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/documents/:id/add',
    name: 'apps-documents-add',
    component: () => import('@/views/apps/documents/document-add/DocumentAdd.vue'),
    meta: {
      pageTitle: 'Create New Document',
      icon: 'FolderPlusIcon',
      breadcrumb: [
        {
          text: 'Folder list',
          to: { name: 'apps-documents-folder-view' },
        },
        {
          text: 'Document list',
          to: { name: 'apps-documents-list' },
        },
        {
          text: 'Create New Document',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/documents/:id/edit',
    name: 'apps-documents-edit',
    component: () => import('@/views/apps/documents/document-edit/DocumentEdit.vue'),
    meta: {
      pageTitle: 'Document Detail',
      icon: 'FolderPlusIcon',
      breadcrumb: [
        {
          text: 'Folder list',
          to: { name: 'apps-documents-folder-view' },
        },
        {
          text: 'Document list',
          to: { name: 'apps-documents-list' },
        },
        {
          text: 'Document Detail',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // ANCHOR: FLIGHTS V2
  // *===============================================---*
  {
    path: '/apps/flights',
    name: 'apps-flightsV2-page',
    component: () => import('@/views/apps/flight-v2/search/index.vue'),
    meta: {
      pageTitle: 'Search',
      breadcrumb: [
        {
          text: 'Flight v2',
          active: true,
        },
      ],
      action: 'booking.searchBooking',
    },
  },
  {
    path: '/apps/flights-v2/result',
    name: 'apps-flightsV2-result',
    component: () => import('@/views/apps/flight-v2/result/index.vue'),
    meta: {
      pageTitle: 'Results',
      breadcrumb: [
        {
          text: 'Flight v2',
          active: true,
        },
      ],
      action: 'booking.searchBooking',
    },
  },
  {
    path: '/apps/flights-v2/cheap-fare-calendar-result',
    name: 'apps-flightsV2-cheap-fare-calendar-result',
    component: () => import('@/views/apps/flight-v2/calendar/index.vue'),
    meta: {
      pageTitle: 'Flight v2',
      breadcrumb: [
        {
          text: 'Results',
          active: true,
        },
      ],
      action: 'booking.searchBooking',
    },
  },
  {
    path: '/apps/flights-v2/reservation',
    name: 'apps-flightsV2-reservation',
    component: () => import('@/views/apps/flight-v2/reservation/index.vue'),
    meta: {
      pageTitle: 'Booking',
      breadcrumb: [
        {
          text: 'Flight v2',
          active: true,
        },
      ],
      action: 'booking.createBooking',
    },
  },
  {
    path: '/apps/flights-v2/class-booking-result',
    name: 'apps-flightsV2-class-booking-result',
    component: () => import('@/views/apps/flight-v2/result-class-booking/index.vue'),
    meta: {
      pageTitle: 'Results',
      breadcrumb: [
        {
          text: 'Flight v2',
          active: true,
        },
      ],
      action: 'booking.searchBooking',
    },
  },
  {
    path: '/apps/flights-v2/reservation',
    name: 'apps-flightsV2-class-booking-reservation',
    component: () => import(
      '@/views/apps/flight-v2/result-class-booking/reservation/index.vue'
    ),
    meta: {
      pageTitle: 'Booking',
      breadcrumb: [
        {
          text: 'Flight v2',
          active: true,
        },
      ],
      action: 'booking.createBooking',
    },
  },

  // *===============================================---*
  // ANCHOR: Reservation
  // *===============================================---*
  {
    path: '/apps/bookings',
    name: 'apps-reservations-list',
    component: () => import('@/views/apps/reservation/reservation-list/ReservationList.vue'),
    meta: {
      pageTitle: 'Reservations',
      breadcrumb: [
        {
          text: 'Reservations',
          active: true,
        },
      ],
      action: 'booking.listBooking',
    },
  },
  {
    path: '/apps/bookings/Hold',
    name: 'apps-reservations_hold-list',
    component: () => import('@/views/apps/reservation/reservation-list/ReservationList.vue'),
    meta: {
      pageTitle: 'Reservations',
      breadcrumb: [
        {
          text: 'Reservations',
          active: true,
        },
      ],
      action: 'booking.listBooking',
    },
  },
  {
    path: '/apps/bookings/Paid',
    name: 'apps-reservations_paid-list',
    component: () => import('@/views/apps/reservation/reservation-list/ReservationList.vue'),
    meta: {
      pageTitle: 'Reservations',
      breadcrumb: [
        {
          text: 'Reservations',
          active: true,
        },
      ],
      action: 'booking.listBooking',
    },
  },
  {
    path: '/apps/bookings/Cancel',
    name: 'apps-reservations_cancel-list',
    component: () => import('@/views/apps/reservation/reservation-list/ReservationList.vue'),
    meta: {
      pageTitle: 'Reservations',
      breadcrumb: [
        {
          text: 'Reservations',
          active: true,
        },
      ],
      action: 'booking.listBooking',
    },
  },
  {
    path: '/apps/bookings/Expired',
    name: 'apps-reservations_expired-list',
    component: () => import('@/views/apps/reservation/reservation-list/ReservationList.vue'),
    meta: {
      pageTitle: 'Reservations',
      breadcrumb: [
        {
          text: 'Reservations',
          active: true,
        },
      ],
      action: 'booking.listBooking',
    },
  },
  {
    path: '/apps/bookings/findByPnrCode',
    name: 'apps-findByPnrCode',
    component: () => import(
      '@/views/apps/reservation/reservation-list/FindBookingByPnrCodePage.vue'
    ),
    meta: {
      pageTitle: 'reservation.findBookingByPnrCode',
      breadcrumb: [
        {
          text: 'Reservations',
          to: { name: 'apps-reservations-list' },
        },
        {
          text: 'reservation.findBookingByPnrCode',
          active: true,
        },
      ],
      action: 'booking.listBooking',
    },
  },
  {
    path: '/apps/bookings/findEticket',
    name: 'apps-findEticket',
    component: () => import('@/views/apps/reservation/find-ticket/FindTicket.vue'),
    meta: {
      pageTitle: 'reservation.viewEticket',
      breadcrumb: [
        {
          text: 'Reservations',
          to: { name: 'apps-reservations-list' },
        },
        {
          text: 'reservation.viewEticket',
          active: true,
        },
      ],
      action: 'booking.listBooking',
    },
  },

  // {
  //   path: '/apps/reservations/detail/:id',
  //   name: 'apps-reservations-detail',
  //   component: () => import('@/views/apps/reservation/reservation-detail/ReservationDetail.vue'),
  //   meta: {
  //     pageTitle: 'Reservation Detail',
  //     icon: 'EditIcon',
  //     breadcrumb: [
  //       {
  //         text: 'Reservation Management',
  //         to: { name: 'apps-reservationTicket-page' },
  //       },
  //       {
  //         text: 'Reservation Detail',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // ANCHOR tra cứu vé Vietjet
  {
    path: '/apps/bookings/findBookingVJ',
    name: 'apps-findBookingVJ',
    component: () => import(
      '@/views/apps/reservation/reservation-list/find-booking-vj/FindBookingVJ.vue'
    ),
    meta: {
      pageTitle: 'Find Booking Vietjet',
      breadcrumb: [
        {
          text: 'Find Booking Vietjet',
          active: true,
        },
      ],
      floor: ['F1'], // F của user
    },
  },
  // ANCHOR thống kê hệ thống giữ chỗ
  {
    path: '/apps/bookings/statistic',
    name: 'apps-bookings-statistic',
    component: () => import(
      '@/views/apps/reservation/reservation-list/booking-statistic/BookingStatistic.vue'
    ),
    meta: {
      pageTitle: 'Booking Statistic',
      breadcrumb: [
        {
          text: 'Booking Statistic',
          active: true,
        },
      ],
      floor: ['F1'], // F của user
      role: ['OPE', 'ADM', 'KTT'],
    },
  },
  // ANCHOR thống kê hệ thống vé qua ngày bay
  {
    path: '/apps/past-day-booking',
    name: 'apps-past-day-booking-list',
    component: () => import(
      '@/views/apps/reservation/reservation-list/PastDayBookingList.vue'
    ),
    meta: {
      pageTitle: 'Past Day Booking List',
      breadcrumb: [
        {
          text: 'Past Day Booking List',
          active: true,
        },
      ],
      // floor: ['F1', 'F2'], // F của user
    },
  },
  {
    path: '/apps/bookings/:id',
    name: 'apps-reservations-modify',
    component: () => import('@/views/apps/reservation/reservation-modify/index.vue'),
    meta: {
      pageTitle: 'Reservation Detail',
      icon: 'EditIcon',
      breadcrumb: [
        {
          text: 'Reservations',
          to: { name: 'apps-reservations-list' },
        },
        {
          text: 'Reservation Detail',
          active: true,
        },
      ],
      action: 'booking.detailBooking',
    },
  },
  {
    path: '/apps/past-day-bookings/:id',
    name: 'apps-past-day-booking-modify',
    component: () => import('@/views/apps/reservation/reservation-modify/index.vue'),
    meta: {
      pageTitle: 'Past Day Booking Detail',
      icon: 'EditIcon',
      breadcrumb: [
        {
          text: 'Past Day Booking List',
          to: { name: 'apps-past-day-booking-list' },
        },
        {
          text: 'Reservation Detail',
          active: true,
        },
      ],
      action: 'booking.detailBooking',
    },
  },
  {
    path: '/apps/bookings/:id/ticket',
    name: 'apps-reservations-ticket',
    component: () => import(
      '@/views/apps/reservation/reservation-modify/ticketFace/ElectronicTicketPage.vue'
    ),
    meta: {
      layout: 'full',
      action: 'booking.detailBooking',
    },
  },

  {
    path: '/apps/bookings/:id/add-flights',
    name: 'apps-reservations-modify-add-flights',
    component: () => import('@/views/apps/reservation/reservation-modify/components/detail/flights-details/add-flights/result/index.vue'),
    meta: {
      pageTitle: 'Add Flights',
      icon: 'SendIcon',
      breadcrumb: [
        {
          text: 'Reservations',
          to: { name: 'apps-reservations-list' },
        },
        {
          text: 'Reservation Detail',
          to: {
            name: 'apps-reservations-modify',
            // params: { id: path.split('/')[3] },
          },
        },
        {
          text: 'Add Flights',
          active: true,
        },
      ],
      action: 'booking.detailBooking',
    },
    // beforeEnter: (to, from, next) => {
    //   const id = to.params.id
    //   const reservationDetailBreadcrumb = {
    //     text: 'Reservation Detail',
    //     to: {
    //       name: 'apps-reservations-modify',
    //       params: { id },
    //     },
    //   }
    //   to.meta.breadcrumb.splice(1, 0, reservationDetailBreadcrumb)
    //   next()
    // },
  },
  {
    // FIXME - Need to delete key 'bookingData' when path is fixed
    path: '/apps/bookings/:id/add-flights-class-booking',
    name: 'apps-reservations-modify-add-flights-class-booking',
    component: () => import('@/views/apps/reservation/reservation-modify/components/detail/flights-details/add-flights/result-class-booking/index.vue'),
    meta: {
      pageTitle: 'Add Flights',
      icon: 'SendIcon',
      breadcrumb: [
        {
          text: 'Reservations',
          to: { name: 'apps-reservations-list' },
        },
        {
          text: 'Reservation Detail',
          to: {
            name: 'apps-reservations-modify',
            // params: { id: path.split('/')[3] },
          },
        },
        {
          text: 'Add Flights',
          active: true,
        },
      ],
      action: 'booking.detailBooking',
    },
  },

  // *===============================================---*
  // ANCHOR: FLIGHT PREPARATION
  // *===============================================---*
  {
    path: '/apps/flight-preparation',
    name: 'apps-flight-preparation-page',
    component: () => import(
      '@/views/apps/flight-preparation/flight-preparation-list/FlightPreparationList.vue'
    ),
    meta: {
      pageTitle: 'Flight Preparation List',
      breadcrumb: [
        {
          text: 'Flight Preparation List',
          active: true,
        },
      ],
    },
  },

  // ANCHOR thống kê hệ thống canh vé rẻ
  {
    path: '/apps/low-fare-tracking-statistic',
    name: 'apps-low-fare-tracking-statistic',
    component: () => import('@/views/apps/low-fare-tracking/index.vue'),
    meta: {
      pageTitle: 'Low Fare Tracking Statistic',
      breadcrumb: [
        {
          text: 'Low Fare Tracking Statistic',
          active: true,
        },
      ],
      floor: ['F1', 'F2'], // F của user
      // hideForProduction: true,
    },
  },

  // *===============================================---*
  // ANCHOR: WORK SCHEDULE // CA TRỰC
  // *===============================================---*
  {
    path: '/apps/work-schedule/parent-agency',
    name: 'apps-parent-workSchedules-page',
    component: () => import('@/views/apps/work-schedule/agency/index.vue'),
    meta: {
      pageTitle: 'schedule.agencyWorkSchedule',
      breadcrumb: [
        {
          text: 'schedule.agencyWorkSchedule',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/work-schedule',
    name: 'apps-workSchedules-page',
    component: () => import('@/views/apps/work-schedule/me/index.vue'),
    meta: {
      pageTitle: 'schedule.workSchedule',
      breadcrumb: [
        {
          text: 'schedule.workSchedule',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/work-schedule/create',
    name: 'apps-workSchedules-create',
    component: () => import('@/views/apps/work-schedule/me/create/index.vue'),
    meta: {
      pageTitle: 'Create Work Schedule',
      breadcrumb: [
        {
          text: 'Work Schedule List',
          to: { name: 'apps-workSchedules-page' },
        },
        {
          text: 'Create Work Schedule',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // ANCHOR: ACCOUNTS PAYABLE // CÔNG NỢ
  // *===============================================---*
  {
    path: '/apps/accounts-payable/list',
    name: 'apps-accountsPayable-list',
    component: () => import('@/views/apps/accounts-payable/AccountsPayable.vue'),
    meta: {
      pageTitle: 'Accounts payable list',
      breadcrumb: [
        {
          text: 'Accounts payable list',
          active: true,
        },
      ],
      action: 'acc_payable.readAccpayble',
    },
  },
  {
    path: '/apps/accounts-payable/detail',
    name: 'apps-accountsPayable-detail',
    component: () => import(
      '@/views/apps/accounts-payable/accounts-payable-detail/AccountsPayableDetail.vue'
    ),
    meta: {
      pageTitle: 'Accounts payable',
      // icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'Accounts payable list',
          to: { name: 'apps-accountsPayable-list' },
        },
        {
          text: 'Detail accounts payable',
          active: true,
        },
      ],
      action: 'acc_payable.readAccpaybleDetails',
    },
  },
  {
    path: '/apps/accounts-payable/f2/detail/:id',
    name: 'apps-accountsPayable-detail-f2',
    component: () => import(
      '@/views/apps/accounts-payable/accounts-payable-f2/detail/AccountsPayableF2Detail.vue'
    ),
    meta: {
      pageTitle: 'Accounts payable',
      // icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'Accounts payable list',
          to: { name: 'apps-accountsPayable-list' },
        },
        {
          text: 'Detail accounts payable',
          active: true,
        },
      ],
      action: 'acc_payable.readAccpaybleDetails',
    },
  },
  {
    path: '/apps/accounts-payable/update',
    name: 'apps-accountsPayable-update',
    component: () => import(
      '@/views/apps/accounts-payable/accounts-payable-update/AccountsPayableUpdate.vue'
    ),
    meta: {
      pageTitle: 'Accounts payable',
      // icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'Accounts payable list',
          to: { name: 'apps-accountsPayable-list' },
        },
        {
          text: 'Detail accounts payable',
          to: { name: 'apps-accountsPayable-detail' },
        },
        {
          text: 'Update accounts payable',
          active: true,
        },
      ],
      action: 'sales_report.updateSaleReport',
    },
  },
  {
    path: '/apps/accounts-payable/import',
    name: 'apps-accountsPayable-import',
    component: () => import(
      '@/views/apps/accounts-payable/accounts-payable-import/AccountsPayableImport.vue'
    ),
    meta: {
      pageTitle: 'Import accounts payable',
      // icon: 'UserPlusIcon',
      breadcrumb: [
        {
          text: 'Accounts payable list',
          to: { name: 'apps-accountsPayable-list' },
        },
        {
          text: 'Import accounts payable',
          active: true,
        },
      ],
      action: 'sales_report.importSalereport',
    },
  },
  // *===============================================---*
  // SECTION Promotion AG
  // *===============================================---*
  {
    path: '/apps/promotion-ag/list',
    name: 'apps-promotionAg-list',
    component: () => import('@/views/apps/promotion-ag/list/index.vue'),
    meta: {
      pageTitle: 'Promotion AG List',
      breadcrumb: [
        {
          text: 'Promotion AG List',
          active: true,
        },
      ],
      // FIXME action: 'promotionAg.listPromotion',
      floor: ['F1', 'F2'],
    },
  },
  {
    path: '/apps/promotion-ag/create',
    name: 'apps-promotionAg-create',
    component: () => import('@/views/apps/promotion-ag/create/index.vue'),
    meta: {
      pageTitle: 'Create New Promotion AG',
      breadcrumb: [
        {
          text: 'Promotion AG List',
          to: { name: 'apps-promotionAg-list' },
        },
        {
          text: 'Create New',
          active: true,
        },
      ],
      // FIXME action: 'promotionAg.createPromotion',
      floor: ['F1', 'F2'],
    },
  },
  {
    path: '/apps/promotion-ag/edit/:id',
    name: 'apps-promotionAg-edit',
    component: () => import('@/views/apps/promotion-ag/edit/index.vue'),
    meta: {
      pageTitle: 'Promotion AG detail',
      breadcrumb: [
        {
          text: 'Promotion AG List',
          to: { name: 'apps-promotionAg-list' },
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
      // FIXME action: 'promotionAg.detailPromotion',
      floor: ['F1', 'F2'],
    },
  },
  // !SECTION

  // *===============================================---*
  // SECTION Promotion Airline
  // *===============================================---*
  {
    path: '/apps/promotion-airline/list',
    name: 'apps-promotionAirline-list',
    component: () => import('@/views/apps/promotion-airline/list/index.vue'),
    meta: {
      pageTitle: 'Promotion Airline List',
      breadcrumb: [
        {
          text: 'Promotion Airline List',
          active: true,
        },
      ],
      floor: ['F1', 'F2'],
    },
  },
  {
    path: '/apps/promotion-airline/create',
    name: 'apps-promotionAirline-create',
    component: () => import('@/views/apps/promotion-airline/create/index.vue'),
    meta: {
      pageTitle: 'Create New Promotion Airline',
      breadcrumb: [
        {
          text: 'Promotion Airline List',
          to: { name: 'apps-promotionAirline-list' },
        },
        {
          text: 'Create New',
          active: true,
        },
      ],
      floor: ['F1', 'F2'],
    },
  },
  {
    path: '/apps/promotion-airline/edit/:id',
    name: 'apps-promotionAirline-edit',
    component: () => import('@/views/apps/promotion-airline/edit/index.vue'),
    meta: {
      pageTitle: 'Promotion Airline detail',
      breadcrumb: [
        {
          text: 'Promotion Airline List',
          to: { name: 'apps-promotionAirline-list' },
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
      floor: ['F1', 'F2'],
    },
  },
  // !SECTION

  // *===============================================---*
  // SECTION Promotion User
  // *===============================================---*
  {
    path: '/apps/promotion-user/list',
    name: 'apps-promotionUser-list',
    component: () => import('@/views/apps/promotion-user/list/PromotionUserList.vue'),
    meta: {
      pageTitle: 'Promotion User List',
      breadcrumb: [
        {
          text: 'Promotion User List',
          active: true,
        },
      ],
      floor: ['F1'],
      role: ['OPE', 'ADM'],
    },
  },
  {
    path: '/apps/promotion-user/create',
    name: 'apps-promotionUser-create',
    component: () => import('@/views/apps/promotion-user/create/PromotionUserCreate.vue'),
    meta: {
      pageTitle: 'Create New Promotion User',
      breadcrumb: [
        {
          text: 'Promotion User List',
          to: { name: 'apps-promotionUser-list' },
        },
        {
          text: 'Create New',
          active: true,
        },
      ],
      floor: ['F1'],
      role: ['OPE', 'ADM'],
    },
  },
  // !SECTION

  // SECTION Configs
  {
    path: '/apps/configs/account-setting',
    name: 'account-settings',
    component: () => import('@/views/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/configs/agency-setting',
    name: 'apps-agencySetting',
    component: () => import('@/views/agency-setting/AgencySetting.vue'),
    meta: {
      pageTitle: 'Agency Settings',
      breadcrumb: [
        {
          text: 'Agency Settings',
          active: true,
        },
      ],
      floor: ['F1', 'F2'],
      role: ['OPE', 'ADM'],
    },
  },
  {
    path: '/apps/configs/fee',
    name: 'apps-configsFee',
    component: () => import('@/views/apps/configs/fee/index.vue'),
    meta: {
      pageTitle: 'Fee Configs',
      breadcrumb: [
        {
          text: 'Fee Configs',
          active: true,
        },
      ],
      // FIXME action: 'promotionAg.listPromotion',
    },
  },
  {
    path: '/apps/configs/service-fee',
    name: 'apps-configsServiceFee',
    component: () => import('@/views/apps/configs/service-fee/ServiceFee.vue'),
    meta: {
      pageTitle: 'Service Fee Configs',
      breadcrumb: [
        {
          text: 'Service Fee Configs',
          active: true,
        },
      ],
      // FIXME action: 'promotionAg.listPromotion',
    },
  },
  {
    path: '/apps/configs/ticket',
    name: 'apps-configsTicket',
    component: () => import('@/views/apps/configs/ticket/index.vue'),
    meta: {
      pageTitle: 'ticket.pageTitle',
      breadcrumb: [
        {
          text: 'electronicTicket',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/configs/display',
    name: 'apps-configsDisplay',
    component: () => import('@/views/apps/configs/display/index.vue'),
    meta: {
      pageTitle: 'configsDisplay',
    },
  },
  {
    path: '/apps/configs/notification',
    name: 'apps-configsNotification',
    component: () => import('@/views/apps/configs/notification/index.vue'),
    meta: {
      pageTitle: 'notification.title',
      breadcrumb: [
        {
          text: 'notification.sendNotification',
          active: true,
        },
      ],
      // hideForProduction: true,
      floor: ['F1'],
      role: ['OPE', 'ADM'],
    },
  },
  {
    path: '/apps/sepay/bank-account',
    name: 'apps-bank-account',
    component: () => import('@/views/apps/sepay/BankAccounts.vue'),
    meta: {
      pageTitle: 'Bank Accounts',
      floor: ['F1'],
      role: ['KTT', 'KTV', 'ADM'],
    },
  },
  {
    path: '/apps/sepay/transaction',
    name: 'apps-transaction',
    component: () => import('@/views/apps/sepay/Transactions.vue'),
    meta: {
      pageTitle: 'Transactions',
      floor: ['F1'],
      role: ['KTT', 'KTV', 'ADM'],
    },
  },
  {
    path: '/apps/sepay/company',
    name: 'apps-company',
    component: () => import('@/views/apps/sepay/Companies.vue'),
    meta: {
      pageTitle: 'Companies',
      floor: ['F1'],
      role: ['KTT', 'KTV', 'ADM'],
    },
  },
  // !SECTION

  // ANCHOR: Danh sách hãng hàng không xuất vé 1G
  // link: https://docs.google.com/spreadsheets/d/1yirqhTbhYt-qFCKPOw0nMti-FUAPQhJJQc74Xq1IDgc/edit#gid=0
  {
    path: '/apps/airlines-1g-can-pay',
    name: 'apps-airlines1gCanPay',
    component: () => import('@/views/apps/docs/airlines1gCanPay/airlines1gCanPay.vue'),
    meta: {
      pageTitle: 'document.airlines1gCanPay.title',
      breadcrumb: [
        {
          text: 'document.airlines1gCanPay.title',
          active: true,
        },
      ],
    },
  },

  // ANCHOR agency-balance (số dư hãng)
  {
    path: '/apps/flights/agency-balance',
    name: 'apps-flights-agencyBalance',
    component: () => import('@/views/apps/agency-balance/AgencyBalance.vue'),
    meta: {
      pageTitle: 'Agency Balance',
      breadcrumb: [
        {
          text: 'Agency Balance',
          active: true,
        },
      ],
      floor: ['F1'], // F của user
      role: ['ADM', 'KTT'], // type của user
    },
  },

  // ANCHOR: terminals
  {
    path: '/apps/terminals',
    name: 'apps-terminals',
    component: () => import('@/views/apps/terminals/Terminals.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      // pageTitle: 'Terminals',
      // breadcrumb: [
      //   {
      //     text: 'Terminals',
      //     active: true,
      //   },
      // ],
      floor: ['F1'], // F của user
      role: ['ADM', 'OPE', 'BE'], // type của user
    },
  },

  // ANCHOR: reference data search
  {
    path: '/apps/utils-travelport',
    name: 'apps-utilsTravelport',
    component: () => import('@/views/apps/utils-travelport/UtilsTravelport.vue'),
    meta: {
      pageTitle: 'Utils',
      breadcrumb: [
        {
          text: 'Utils',
          active: true,
        },
      ],
    },
  },
  // ANCHOR Retail Package
  // packgage-configs
  {
    path: '/apps/packgage-configs',
    name: 'apps-packageConfigs-list',
    component: () => import('@/views/retail-package/package-config/PackageConfigList.vue'),
    meta: {
      pageTitle: 'Package configs',
      breadcrumb: [
        {
          text: 'Package configs',
          active: true,
        },
      ],
      floor: ['F1', 'F2'], // F của user
      role: ['ADM', 'OPE'], // type của user
      // hideForProduction: true,
    },
  },
  // agency-packgage-configs
  {
    path: '/apps/agency-packgage-configs',
    name: 'apps-agencyPackageConfigs-list',
    component: () => import(
      '@/views/retail-package/agency-package-config/AgencyPackageConfigList.vue'
    ),
    meta: {
      pageTitle: 'Agency package configs',
      breadcrumb: [
        {
          text: 'Agency package configs',
          active: true,
        },
      ],
      floor: ['F1', 'F2'], // F của user
      role: ['ADM', 'OPE'], // type của user
      // hideForProduction: true,
    },
  },
  // agency-packgage-history
  {
    path: '/apps/agency-packgage-history',
    name: 'apps-agencyPackageHistory-list',
    component: () => import('@/views/retail-package/agency-package-config/AgencyPackageHistoryList.vue'),
    meta: {
      pageTitle: 'Agency package history',
      breadcrumb: [
        {
          text: 'Agency package history',
          active: true,
        },
      ],
      floor: ['F1', 'F2'], // F của user
      role: ['ADM', 'OPE'], // type của user
      // hideForProduction: true,
    },
  },
  {
    path: '/apps/agency-packgage-order',
    name: 'apps-agencyPackageOrder-list',
    component: () => import(
      '@/views/retail-package/agency-package-order/AgencyPackageOrderList.vue'
    ),
    meta: {
      pageTitle: 'Agency package order',
      breadcrumb: [
        {
          text: 'Agency package order',
          active: true,
        },
      ],
      floor: ['F1', 'F2'], // F của user
      role: ['ADM', 'OPE'], // type của user
      // hideForProduction: true,
    },
  },
  {
    path: '/apps/package-coupons',
    name: 'apps-packageCoupon-list',
    component: () => import('@/views/retail-package/package-coupon/PackageCouponList.vue'),
    meta: {
      pageTitle: 'Package Coupons',
      breadcrumb: [
        {
          text: 'Package Coupons',
          active: true,
        },
      ],
      floor: ['F1'], // F của user
      role: ['ADM', 'OPE'], // type của user
    },
  },

  // ICONS list
  {
    path: '/apps/icons',
    name: 'apps-icons-list',
    component: () => import('@/views/icons/IconList.vue'),
    meta: {
      pageTitle: 'Icons',
      breadcrumb: [
        {
          text: 'Icons',
          active: true,
        },
      ],
      hideForProduction: true,
    },
  },
]
