export default {
  message: {
    title: 'Card Title',
    text: 'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
    pagelength: 'Showing 1 to',
    of: 'of',
    pageText2: 'entries',
    seachLabel: 'Search',
    SearchPlaceholder: 'Search',
    tableHeader: {
      name: 'Name',
      email: 'Email',
      date: 'Date',
      salary: 'Salary',
      status: 'Status',
      action: 'Action',
    },
  },
  'UI Elements': 'UI Elements',
  'Forms & Tables': 'Forms & Tables',
  Pages: 'Pages',
  'Charts & Maps': 'Charts & Maps',
  Others: 'Others',
  Typography: 'Typography',
  Colors: 'Colors',
  Feather: 'Feather',
  Cards: 'Cards',
  Basic: 'Basic',
  Advance: 'Advance',
  Statistic: 'Statistic',
  Analytic: 'Analytic',
  'Card Action': 'Card Action',
  Components: 'Components',
  Alert: 'Alert',
  Aspect: 'Aspect',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Breadcrumb: 'Breadcrumb',
  Button: 'Button',
  'Button Group': 'Button Group',
  'Button Toolbar': 'Button Toolbar',
  Calendar: 'Calendar',
  Carousel: 'Carousel',
  Collapse: 'Collapse',
  Dropdown: 'Dropdown',
  Embed: 'Embed',
  Image: 'Image',
  'List Group': 'List Group',
  Media: 'Media',
  Modal: 'Modal',
  Nav: 'Nav',
  Overlay: 'Overlay',
  Pagination: 'Pagination',
  'Pagination Nav': 'Pagination Nav',
  Pill: 'Pill',
  'Pill Badge': 'Pill Badge',
  Popover: 'Popover',
  Progress: 'Progress',
  Sidebar: 'Sidebar',
  spinner: 'spinner',
  Tab: 'Tab',
  Time: 'Time',
  Toasts: 'Toasts',
  Tooltip: 'Tooltip',
  Extensions: 'Extensions',
  'Sweet Alert': 'Sweet Alert',
  'Quill Editor': 'Quill Editor',
  'Drag & Drop': 'Drag & Drop',
  Swiper: 'Swiper',
  Clipboard: 'Clipboard',
  'Video Player': 'Video Player',
  'Context Menu': 'Context Menu',
  Toastification: 'Toastification',
  I18n: 'I18n',
  Slider: 'Slider',
  Tour: 'Tour',
  'Auto Suggest': 'Auto Suggest',
  Tree: 'Tree',
  'Date Time Picker': 'Date Time Picker',
  'Vue Select': 'Vue Select',
  'Forms Elements': 'Forms elements',
  Select: 'Select',
  Switch: 'Switch',
  Checkbox: 'Checkbox',
  Radio: 'Radio',
  Input: 'Input',
  Textarea: 'Textarea',
  Spinbutton: 'Spinbutton',
  'Input Group': 'Input Group',
  'Form Rating': 'Form Rating',
  'Form Tag': 'Form Tag',
  'Form Datepicker': 'Form Datepicker',
  'Form Timepicker': 'Form Timepicker',
  'File Input': 'File Input',
  'Input Mask': 'Input Mask',
  'Form Layout': 'Form Layout',
  'Form Wizard': 'Form Wizard',
  'Form Validation': 'Form Validation',
  'Form Repeater': 'Form Repeater',
  'BS Table': 'BS Table',
  'Good Table': 'Good Table',
  Charts: 'Charts',
  'Apex Chart': 'Apex Chart',
  Chartjs: 'Chartjs',
  Echart: 'Echart',
  Leaflet: 'Leaflet',
  Profile: 'Profile',
  'Account Settings': 'Account Settings',
  'Agency Settings': 'Agency Settings',
  'Change Password': 'Change Password',
  Faq: 'Faq',
  'Knowledge Base': 'Knowledge Base',
  Pricing: 'Pricing',
  Blog: 'Blog',
  List: 'List',
  Detail: 'Detail',
  Edit: 'Edit',
  Search: 'Search',
  Results: 'Results',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  Support: 'Support',
  'Raise Support': 'Raise Support',
  Documentations: 'Documentations',
  Dashboards: 'Dashboards',
  eCommerce: 'eCommerce',
  Analytics: 'Analytics',
  'Apps & Pages': 'Apps & Pages',
  Email: 'Email',
  Chat: 'Chat',
  Todo: 'Todo',
  Invoice: 'Invoice',
  'Invoice List': 'Invoice List',
  'Invoice Create': 'Create Invoice',
  'Invoice Detail': 'Invoice Details',
  'Invoice Replace': 'Invoice Replace',
  'Invoice Adjust': 'Invoice Adjust',
  'Invoice Preview': 'Invoice Preview',
  'Certificate Manage': 'Certificate Manage',
  'Release Registration': 'Release Registration',
  Declaration: 'Declaration',
  'Authorization Declaration': 'Authorization Declaration',
  'Invoice Template': 'Invoice Template',
  Preview: 'Preview',
  Add: 'Add',
  Shop: 'Shop',
  Details: 'Details',
  Wishlist: 'Wishlist',
  Checkout: 'Checkout',
  User: 'User',
  View: 'View',
  Authentication: 'Authentication',
  'Login v1': 'Login v1',
  'Login v2': 'Login v2',
  'Register v1': 'Register v1',
  'Register v2': 'Register v2',
  'Forget Password v1': 'Forget Password v1',
  'Forget Password v2': 'Forget Password v2',
  'Forgot Password v1': 'Forgot Password v1',
  'Forgot Password v2': 'Forgot Password v2',
  'Reset Password v1': 'Reset Password v1',
  'Reset Password v2': 'Reset Password v2',
  Miscellaneous: 'Miscellaneous',
  'Coming Soon': 'Coming Soon',
  'Not Authorized': 'Not Authorized',
  'Under Maintenance': 'Under Maintenance',
  Error: 'Error',
  Statistics: 'Statistics',
  'Card Actions': 'Card Actions',
  'Media Objects': 'Media Objects',
  Timeline: 'Timeline',
  'Access Control': 'Access Control',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Mail Templates': 'Mail Templates',
  Welcome: 'Welcome',
  'Reset Password': 'Reset Password',
  'Verify Email': 'Verify Email',
  'Deactivate Account': 'Deactivate Account',
  Promotional: 'Promotional',
  'Page Layouts': 'Page Layouts',
  'Collapsed Menu': 'Collapsed Menu',
  'Layout Boxed': 'Layout Boxed',
  'Without Menu': 'Without Menu',
  'Layout Empty': 'Layout Empty',
  'Layout Blank': 'Layout Blank',
  Case: 'Case',
  Queue: 'Queue',
  'Preview temp': 'Preview temp',
  10: '10',

  Booking: 'Booking',
  Bookings: 'Bookings',
  Reservations: 'Reservations',
  'Reservation Detail': 'Reservation Detail',
  'Reservation Ticket': 'Reservation Ticket',

  Customer: 'Customer',
  Employee: 'Employee',
  Agency: 'Agency',
  Contract: 'Contract',
  Receipt: 'Receipt',
  Receipts: 'Receipts',
  Document: 'Document',
  Account: 'Account',
  Documents: 'Documents',

  'Create New Employee': 'Create New Employee',
  'Employee management': 'Employee management',
  'Create New': 'Create New',
  'Employee detail': 'Employee detail',
  'Employee List': 'Employee List',
  'Create New Customer': 'Create New Customer',
  'Customer management': 'Customer management',
  'Customer detail': 'Customer detail',
  'Customer List': 'Customer List',
  'Contact Add': 'Contact Add',
  Contacts: 'Contacts',
  'Contact Edit': 'Contact Edit',
  'Create New Agency': 'Create New Agency',
  'Agency Management': 'Agency Management',
  'Agency Detail': 'Agency Detail',
  'Agency List': 'Agency List',
  'Create New Receipt': 'Create New Receipt',
  'Receipt Management': 'Receipt Management',
  'Receipt Detail': 'Receipt Detail',
  'Receipt List': 'Receipt List',
  Flight: 'Flight',
  'Flight v2': 'Flight',
  general: 'General',
  changePassword: 'Change Pasword',
  'Search flights': 'Search flights',
  Tickets: 'Tickets',
  'Error Notification': 'Error Notification',
  'Accounts payable general': 'Accounts payable',
  'Accounts payable': 'Accounts payable',
  'Account Payable Retail': 'Account Payable Retail',
  'Accounts payable list': 'Accounts payable list',
  'Detail accounts payable': 'Detail accounts payable',
  'Update accounts payable': 'Update accounts payable',
  'Import accounts payable': 'Import Excel',
  'Sale Report': 'Sale Report',
  'Sale Report Retail': 'Sale Report Retail',
  'Sale Report Refund Ticket': 'Refund entitled to pax',
  Role: 'Role',

  // ANCHOR: configs
  Settings: 'Settings',
  'My account': 'My account',
  'Agency account': 'Agency account',
  Configs: 'Configs',
  'Fee Configs': 'Agency fee config',
  'Service Fee Configs': 'Customer fee config',
  electronicTicket: 'E-Ticket config',
  configsDisplay: 'Display Configs',
  'Send Notification': 'Send Notification',

  // ANCHOR: Sale Report
  'Create sale report manually': 'Create sale report manually',
  'Sale report detail': 'Sale report detail',

  Promotion: 'Promotion',
  // ANCHOR: Promotion AG
  'Promotion AG': 'Promotion AG',
  'Promotion AG List': 'Promotion AG List',
  'Create New Promotion AG': 'Create New Promotion AG',
  'Promotion AG detail': 'Promotion AG detail',

  // ANCHOR Promotion Airline
  'Promotion Airline': 'Promotion Airline',
  'Promotion Airline List': 'Promotion Airline List',
  'Create New Promotion Airline': 'Create New Promotion Airline',
  'Promotion Airline Detail': 'Promotion Airline Detail',

  // ANCHOR: Promotion User
  'Promotion User': 'Promotion User',
  'Promotion User List': 'Promotion User List',
  'Create New Promotion User': 'Create New Promotion User',
  'Promotion User Detail': 'Promotion User Detail',

  // ANCHOR Other
  'AIRDATA pending': 'AIRDATA pending',
  Template: 'Template',

  // ANCHOR: Work Schedule
  'Work Schedule': 'Work Schedule',
  'Work Schedule List': 'Work Schedule List',
  'Create Work Schedule': 'Create Work Schedule',

  // ANCHOR: Airlines 1G can pay
  'Airlines 1G can pay': 'List of airlines issuing BSP tickets',

  // ANCHOR Document Management cong van tai lieu
  'Document Management': 'Document Management',
  'Folder list': 'Folder list',
  'Document list': 'Document list',
  'Create New Document': 'Create New Document',
  'Document Detail': 'Document Detail',

  // ANCHOR: Flight Preparation list
  'Flight Preparation': 'Flight Preparation',
  'Flight Preparation List': 'Flight Preparation List',

  // ANCHOR Agency Balance
  'Agency Balance': 'Agency Balance',

  // ANCHOR find booking VJ
  'Find Booking Vietjet': 'Find Booking Vietjet',
  // ANCHOR retail
  Retail: 'Retail',
  'Retail Report': 'Retail Report',
  'Retail Bookings': 'Retail Bookings',
  'Retail Config Service Fee': 'Retail Config Service Fee',

  // ANCHOR booking statistic
  'Booking Statistic': 'Hold Booking Statistic',
  'Low Fare Tracking Statistic': 'Low fare Tracking Statistic',
  'Past Day Booking List': 'Past Day Booking List',
  Terminals: 'Terminals',

  'Reference Data Search': 'Reference Data Search',
  Utils: 'Utils',
  'Agency package configs': 'Agency package configs',
  'Agency package history': 'History of Agency package',
  'Agency package order': 'Agency package order',
  'Package configs': 'Package configs',
  'Package configs create': 'Package configs create',
  'Package configs detail': 'Package configs detail',
  'Package Coupons': 'Package Coupons',
  'Check-in cancellation VNA': 'Check-in cancellation VNA',
  titleRepicient: 'Topup request',
  titleSender: 'Topup',
  officialDispatch: 'Official Dispatch',
  sepayManager: 'Sepay Manager',
  'Bank accounts': 'Bank accounts',
  Transactions: 'Transactions',
  Companies: 'Companies',
  'Resell Ticket': 'Resell Ticket',
  'Search resell Ticket': 'Resell ticket list',
  'Manager resell Ticket': 'Manager resell Ticket',
  suggestShopVJ: 'Suggest marketplace',
  'Support Request': 'Support Request',
}
